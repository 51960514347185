@import '_generic';

// General
*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	height: 100%;
	width: 100%;
	touch-action: manipulation;
}

body {
	width: 100%;
	min-height: 100%;
	background: $white-text; // overflow: hidden;
	-webkit-overflow-scrolling: touch;
	-webkit-backface-visibility: hidden;
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	text-size-adjust: 100%;
	padding-bottom: 61px;
	position: relative;
	text-rendering: optimizeSpeed;
	margin: 0;

	&.maintenance {
		padding-bottom: 0;
	}

	&.new-ha {
		background-color: $ha-bg-color;
	}
}

.mb-3,
.my-3 {
	margin-bottom: 1rem !important;
}

.text-capitalize {
	text-transform: capitalize;
}

// Fixed header, wrapper section
.general-wrapper {
	padding: 30px 0px 30px 0px;

	&.enable-wellness {
		padding: 30px 0px 30px 0px;
	}

	&.health-history-wrapper {
		padding: 30px 0px 30px 0px !important;
		margin-top: 0px !important;
		height: auto;
	}

	@media screen and (max-width: 991.98px) {
		padding: 15px 0px 70px 0px;

		&.enable-wellness {
			padding: 15px 10px 70px 10px;
		}

		&.health-history-wrapper {
			padding: 15px 10px 30px 10px !important;
		}
	}
}

.disable-wrapper-scroll {
	overflow: hidden;
}

.ehe {
	// min-height: calc(100vh - 75px);
}

a {
	cursor: pointer;
	text-decoration: none;
	transition: all 0.2s ease;

	&:hover {
		text-decoration: none !important;
	}

	&.text-decoration-underline {
		text-decoration: underline;
	}
}

// Remove blue border
* {
	&:focus {
		outline: none !important;
	}
}

.center {
	display: table !important;
	float: none;
	margin: 0 auto;
}

.align-center {
	text-align: center;
}

//heading
.section-heading {
	color: $blue-color !important;
}

.user-exists-error {
	color: $blue-color !important;
}

.primary-color-text {
	color: $blue-color !important;
}

.default {
	color: $default-text-color !important;
}

.secondary-color-text {
	color: $secondary-font-color;
}

.error-border {
	border: 1px solid $error-message-text-color !important;
}

// Error message
.error-message {
	display: inline;
	color: $error-message-text-color;
	font-size: rem(12);
	// position: absolute;
	// top: calc(100% + -4px);
	// transition: all 0.5s ease;
	// transform: translateY(10px);
}

.table-header {
	background-color: $light-background-color;
}

.inline-error-message {
	color: $error-message-text-color;
}

.base-bar {
	margin: 20px 0;
}

li:focus {
	outline: none;
}

.f-1-c {
	width: 100%;
}

.f-4-c {
	margin: 30px 0;
}

.light-weight-text {
	font-weight: 600;
}

.text-red {
	color: $red-color;
}

.section-divider {
	margin: 15px 0;

	p.f-2-c {
		padding: 0 25px;
	}
}

.padding-adjustment {

	.section-question,
	.mat-mdc-form-field {
		margin-left: -15px;
	}
}

#bodyMapGroup {
	table {
		th {
			text-align: center;
			font-weight: 500;
		}
	}
}

// Sidebar Navigation
ul.global-sidebar-nav {
	padding: 0;
	margin: 0;
	text-align: right;

	.li-no-border {
		border: none !important;
	}

	li:not(.active) {
		color: tint($header-color, 30);
	}

	li {
		&:first-child {
			padding-top: 0;
		}

		list-style: none;
		padding-bottom: 10px;
		padding-top: 10px;
		cursor: pointer;
		font-weight: 600;
		color: tint($header-color, 30); // opacity: 0.6;
		font-size: rem(18); // opacity: 0.6;

		// border-bottom: 1px solid $cancel-button-color;
		ul {
			display: none;
		}

		transition: al4 0.3s ease;

		&:hover {
			font-weight: 600;
			color: $header-color; // opacity: 1;
		}

		&.active {
			font-weight: 600;
			color: $header-color; // opacity: 1;

			// border-bottom: 1px solid $active-menu-color;
			ul {
				display: block;
				margin-top: 8px;

				li {
					&.active {
						font-weight: 400;
						color: $header-color;
					}

					&:hover {
						font-weight: 400;
						color: $header-color;
					}

					padding-bottom: 6px;
					padding-top: 6px;
					border-bottom: none;
					font-size: rem(16);
					font-weight: 400;
					color: $placeholder-text-color;
				}
			}
		}

		&:focus {
			outline: none;
		}
	}
}

// Updated Sidebar Navigation
.aside-module {
	border-bottom: 1px solid $module-border-color;
	margin-bottom: 20px;
	padding-bottom: 10px;

	.h-title {
		text-align: right;
		color: $blue-color;
		font-size: rem(18);
		font-weight: 600;
		margin-bottom: 16px;
	}

	.module-content {
		.title {
			text-align: right;
			color: $gray-heading-color;
			border-bottom: 1px dashed $module-border-color;
		}

		.send-message-textarea {
			width: 100%;
			text-align: right;
		}

		.button {
			float: right;
			margin-bottom: 20px;
		}

		ul {
			padding: 0;
			list-style: none;

			li {
				@include clearfix;

				p {
					text-align: right;
					color: $gray-color;
				}

				a {
					color: $gray-color;
					float: right;
					display: inline-block;
					cursor: pointer;
					margin-bottom: 1rem;
					font-size: rem(16);

					span {
						margin-left: 10px;
						display: inline-block;
					}

					&:hover {
						color: tint($blue-color, 25);
					}

					&.active {
						font-weight: 600;
						color: $blue-color;
					}

					&.underline {
						border-bottom: 1px solid $blue-color;
						color: $blue-color;

						&:hover {
							border-bottom: 1px solid tint($blue-color, 25);
						}

						&.active {
							font-weight: 600;
							color: $blue-color;
							border-bottom: 1px solid tint($blue-color, 25);
						}
					}
				}
			}
		}
	}
}

// Badge
.badge {
	background-color: $badge-background-color;
	color: #fff;
	padding: 2px 6px;
	border-radius: 40px;
	font-size: rem(12);
}

// Table
.table-theme {
	width: 100%;

	thead {
		th {
			background-color: #e0eced;
			padding: 8px 12px;
		}
	}

	tbody {
		tr {
			td {
				padding: 14px 12px;
			}

			border-bottom: 1px solid $border-color;
		}
	}
}

.doc-info {
	box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.3);
	margin: 15px;
	padding: 20px;
	border-radius: 3px;
	flex-basis: calc(50% - 30px) !important;
	flex-grow: unset !important;
	flex-wrap: wrap;
}

.chip {
	font-size: rem(14);
	padding: 2px 10px;
	border-radius: 17px;
	border: 1px solid $fill-button-color;
	color: $fill-button-color;
}

.chip-promotional {
	font-size: rem(14);
	padding: 2px 10px;
	border-radius: 17px;
	border: 1px solid $error-message-text-color;
	color: $error-message-text-color;
}

// Wellness - Health Measurements, Lab Results, Lifestyle
.health-measure-wrapper,
.lab-results-wrapper,
.lifestyle-wrapper {
	h2 {
		margin-bottom: 20px;
	}

	.section {
		padding: 10px 20px;
		border-bottom: 1px solid $cancel-button-color;
		border-top: 1px solid $cancel-button-color;
		margin-bottom: 20px;
	}

	.section-block {
		box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.3);
		flex-basis: calc(100% - 30px);
		flex-grow: unset;
		flex-wrap: wrap;
		border-radius: 3px;
	}

	.section-block-content {
		flex-basis: calc(100% - 30px);
		flex-grow: unset;
		flex-wrap: wrap;

		.last-update-on {
			color: $cancel-button-color;
			font-size: rem(9);
			font-weight: 600;
			text-transform: uppercase;
			margin-top: 4px;
			display: block;
			padding: 0 10px;
		}

		.col-md-3,
		.col {
			padding: 20px;

			p {
				padding: 0 10px;
			}
		}
	}

	.section-block-header {
		padding: 5px 10px;
		font-size: rem(14);
		font-weight: 600;
		border-bottom: 1px solid $new-border-color;
	}

	.section-wrapper {
		margin-top: 20px;

		&:first-child {
			margin-top: 0;
		}
	}
}

.block-disabled {
	cursor: not-allowed !important;
	background: $disabled-color !important;
	pointer-events: none;
	opacity: 0.5;
}

.mat-mdc-dialog-container {
	background-color: transparent;
	box-shadow: none;
}

.disabled-button {
	opacity: 0.5;
	pointer-events: none;
}

.clickable {
	cursor: pointer;
}

.text-link {
	color: $primary-font-color !important;
}

.italic {
	font-style: italic;
}

.font-thick {
	font-weight: 900;
}

.margin-top-40 {
	margin-top: 40px;
}

.margin-top-30 {
	margin-top: 30px;
}

.margin-top-5 {
	margin-top: 5px;
}

.margin-top-20 {
	margin-top: 20px;
}

.margin-top-10 {
	margin-top: 10px;
}

.margin-bottom-20 {
	margin-bottom: 20px;
}

.padding-10 {
	padding: 10px;
}

.padding-20 {
	padding: 20px;
}

.cancel-icon-btn {
	color: $icon-button-color;
	cursor: pointer;
}

.health-history-table {
	.align-center {
		text-align: center;
		font-weight: 100;
		font-size: rem(13);
	}
}

.margin-left-10 {
	margin-left: 10px !important;
}

// loader on type ahead
i.align-loader-right {
	position: absolute;
	right: 0;
	color: $primary-font-color;
	animation: inline-loader 1s linear 0s infinite normal forwards;
}

.toggle-ssn {
	position: absolute;
	right: 0;
	left: 90%;
	color: $primary-font-color;
	cursor: pointer;

	svg {
		height: 18px;
		padding: 0 2px;

		path {
			fill: #888888; // default color
		}
	}

	&.hide {}

	&.show {
		svg {
			path {
				fill: $primary-font-color;
			}
		}
	}
}

@keyframes inline-loader {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.fa.fa-phone {
	transform: rotate(90deg);
}

// Referral Styles
.r-p-title-container {
	padding-top: 10px;
	margin-bottom: 20px;
	@include clearfix;

	.title {
		float: left;

		h2 {
			font-size: rem(24);
			color: $primary-font-color;
			margin-bottom: 0;
		}
	}

	.addons {
		float: right;

		a {
			color: $primary-font-color;

			i {
				padding-right: 6px;
			}

			display: inline-block;

			&:hover {
				color: tint($primary-font-color, 20%);
			}
		}
	}
}

.c-r-module {
	border-radius: 4px;

	.title {
		width: 95%;
		@include clearfix;

		h3 {
			float: left;
			font-size: rem(18);
			font-weight: 700;
			color: $blue-color;
			margin-bottom: 0;
		}

		p {
			float: right;
			margin-bottom: 0;
			font-size: rem(14);
			color: $blue-color;
		}

		@media screen and (max-width: 767.98px) {
			h3 {
				margin-bottom: 0px;
				display: block;
				width: 100%;
			}

			p {
				float: left;
			}
		}
	}

	.c-r-content {
		width: 100%;
		padding: 20px 0 0 0;

		.c-r-row {
			display: table;
			width: 100%;

			.c-r-r-label {
				width: 240px;

				p {
					font-weight: 600;
					color: $global-text-color;
				}
			}

			.c-r-r-label,
			.c-r-r-val {
				display: table-cell;
				vertical-align: top;

				p {
					color: $global-text-color;
					margin-bottom: 14px;
				}
			}
		}

		@media screen and (max-width: 767.98px) {
			.c-r-row {

				.c-r-r-label,
				.c-r-r-val {
					width: 50%;
				}
			}
		}
	}

	.c-r-expand {
		@include clearfix;

		a {
			float: right;
			color: $primary-font-color;
			font-weight: 600;
			opacity: 0.6;

			i {
				display: inline-block;
				vertical-align: text-bottom;
				margin-left: 4px;
			}

			&:hover {
				color: tint($primary-font-color, 20%);
			}
		}
	}
}

.with-request-appointment {
	.c-r-module {
		.c-r-content {
			.c-r-row.c-r-clear-border {
				border-bottom: none;
				margin-bottom: 0;
			}
		}
	}

	.c-r-module {
		.c-r-content {
			.c-r-r-label {
				width: 290px;
				padding-right: 20px;
			}
		}
	}
}

.address-checkbox {

	/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
	mat-checkbox {
		span {
			display: block;
		}
	}

	.link {
		margin-left: 24px;

		&.with-request-appointment {
			margin-left: 0;
		}
	}
}

.appointment-request {
	padding-bottom: 30px;
	border-bottom: 1px solid $cancel-button-color;
	margin-bottom: 10px;

	.c-r-module {
		border: 1px solid $cancel-button-color;
		@extend .global-box-shadow;
		border-radius: 10px;

		.title {
			width: 100%;
			padding: 15px;
			border-bottom: 1px solid $cancel-button-color;
		}

		.c-r-content {
			padding: 15px;
		}
	}
}

.with-request-appointment {
	.r-r-module-content {
		.ngx-slider {
			margin: 20px 0 35px;
		}

		.full-width {
			width: 100%;
		}

		.notes-field {
			margin-top: 10px;
		}
	}
}

.link {
	cursor: pointer;
	font-weight: 400;
	color: $primary-font-color !important;
	padding-bottom: 10px;

	&:hover {
		color: tint($primary-font-color, 20%);
	}
}

.no-record {
	text-align: center;
}

// Scheduling Appointment
.scheduling-wrapper,
.request-new-time-slot-wrapper,
.review-appointment-wrapper,
.appointment-confirmation-wrapper,
.appointments-wrapper,
.cancel-appointment-wrapper {
	.appt-title {
		color: $blue-color;
		font-size: rem(21);
		margin-bottom: 20px;
	}

	.reschedule-appointment-info {
		.g-content {
			padding: 10px;
			width: 100%;
			color: $default-text-color;

			.content {
				font-size: 14px;
				line-height: 17px;
				float: right;
				width: calc(100% - 26px);

				p {
					display: block;
					margin-bottom: 0;
				}
			}

			@media screen and (max-width: 767.98px) {
				.col-md-5 {
					margin-bottom: 20px;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.divider {
		border-bottom: 1px dashed $secondary-font-color !important;
		margin-bottom: 1rem !important;
	}

	i.fa {
		color: $green-one;
	}
}

.appointment-info {
	border: 1px solid $cancel-button-color;
	margin-left: 0%;
	border-radius: 10px;
	margin-bottom: 30px;
	@extend .global-box-shadow;

	.heading {
		border-bottom: 1px solid $cancel-button-color;
		padding: 10px 15px;
		@include clearfix;

		h6 {
			float: left;
			margin-bottom: 0;
			font-size: rem(16);

			span {
				margin-left: 4px;
				font-size: rem(13);
				color: $gray-color;
				margin-top: 2px;
			}
		}

		span:not(.appt-status) {
			float: right;
			line-height: normal;

			i {
				font-size: 12px;
				color: $blue-color;
			}
		}
	}

	.g-content {
		width: 100%;
		color: $default-text-color; // display: inline-flex;
		// justify-content: space-between;
		// align-items: flex-start;
		padding: 15px;

		i.fa {
			padding-top: 5px;
			float: left;
			width: 20px;
			height: 20px;
			text-align: center;
			color: $blue-color;
		}

		.content {
			float: right;
			width: calc(100% - 26px);

			p {
				display: block;
				margin-bottom: 0;
			}

			a,
			.link {
				display: inline-block;
				color: $blue-color;

				&:hover {
					color: tint($blue-color, 30);
				}
			}
		}

		.col-md-4 {
			margin-bottom: 20px;
		}
	}
}

// Making cursor non active - non clickable
.non-clickable {
	cursor: initial !important;
}

// carousel component style in dashboard
.d-carousel {
	.d-carousel-wrapper {
		border-bottom: 1px solid $cancel-button-color;
		margin-bottom: 10px;
		display: table;
		width: 100%;
		padding-bottom: 10px;

		a {
			display: block;
		}

		.d-carousel-content {
			width: 90%;
			display: table-cell;
			padding-right: 20px;

			h6 {
				color: $primary-font-color;
				font-size: rem(18);
				margin-bottom: 6px;
			}

			p {
				font-size: rem(16);
				line-height: 18px;
				color: $placeholder-text-color;
			}
		}

		.d-carousel-next {
			width: 10%;
			display: table-cell;
			vertical-align: middle;

			i {
				font-size: rem(25);
				color: $primary-font-color;
				opacity: 0.6;
			}
		}
	}

	.d-carousel-pagination {
		ul {
			text-align: center;
			padding-left: 0;
			margin-bottom: 0;

			li {
				display: inline-block;

				a {
					display: block;
					height: 12px;
					width: 12px;
					margin: 8px 8px 0 8px;
					border-radius: 12px;
					background: $white-background-color;
					border: 1px solid #d4d4d4;

					&.active {
						background: $primary-font-color;
					}
				}
			}
		}
	}
}

// Tables - generic fixes
.table-container {
	@media screen and (max-width: 767.98px) {

		// overflow-x: auto;
		.ehe-table {

			// width: 700px;
			thead {
				display: none;
			}

			tbody {
				tr {
					td {
						.table-label {
							display: inline;
							font-weight: 600;
						}

						display: block;
						padding: 4px 0;

						&:last-child {
							padding-bottom: 10px;
						}

						&:first-child {
							padding-top: 10px;
							color: $blue-color;
							font-size: rem(20);
						}
					}
				}
			}
		}
	}
}

.ehe-table {
	width: 100%;

	thead {
		th {
			background-color: $header-color;
			padding: 8px 12px;
			color: $white-text;
		}
	}

	tbody {
		tr {
			border-bottom: 1px solid $border-color;

			td {
				.table-label {
					display: none;
				}

				padding: 14px 12px;

				a {
					padding-bottom: 0;
				}
			}
		}
	}
}

.table-condensed{
	width: 70%;
}

.procedure-select-box {

	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.mat-form-field-appearance-legacy .mat-form-field-underline {
		height: 0px;
	}
}

// For adding link to div
.link {
	cursor: pointer;
	font-weight: 400;
	color: $blue-color;
	padding-bottom: 10px;
	display: inline-block;

	&:hover {
		color: tint($blue-color, 20%);
	}
}

.display-block {
	display: block;
	margin-bottom: 0;
}

// Static Page Content (Terms, Privacy & Privacy Practices)
.static-content-wrapper {
	@media screen and (max-width: 991.98px) {
		margin-top: 30px;
	}

	.title-container {
		h3 {
			color: $blue-color;
			font-size: rem(24);
			font-weight: 600;
			margin-bottom: 4px;
		}

		p {
			margin-bottom: 0;
			color: $gray-heading-color;
		}

		margin-bottom: 30px;
	}

	p {
		color: $default-text-color;
		margin-bottom: 20px;

		&.sub-title {
			margin-bottom: 20px;
			color: $blue-color;
			font-weight: 600;
			font-size: rem(16);
		}

		&.general-title {
			margin-bottom: 20px;
			color: $blue-color;
			font-weight: 600;
			font-size: rem(20);
		}

		a {
			color: $blue-color;

			&:hover {
				color: tint($blue-color, 30);
			}
		}
	}

	.group {
		margin-bottom: 20px;

		.address {
			p {
				margin-bottom: 0;
			}
		}
	}
}

.procedure-select-box {
	.mat-mdc-form-field {

		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-wrapper {

			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			.mat-form-field-flex {

				/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
				.mat-form-field-infix {
					padding-right: 20px;
					border-bottom: 0px;

					input {
						cursor: pointer;
					}
				}
			}
		}
	}

	.major-menu {

		/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
		.mat-checkbox-layout {
			margin: 0;
		}
	}
}

.ehe-bridge-wrapper {
	background: url('/assets/images/bridge-bg.jpg') no-repeat;
	width: 100%;
	background-size: contain;
	overflow-x: hidden;
	padding-top: 70px;

	.width-50 {
		width: 50%;
	}

	.image {
		position: relative;
		right: -28%;
		height: 40vw;
		margin-top: 26%;
	}

	.content-box.form-wrapper {
		background-color: #ffffff;
		width: 65%;
		margin: 50% auto 10%;
		border-radius: 4px;
		box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.13);
	}

	@media screen and (max-width: 767.98px) {
		background-image: none !important;

		.clearfix {
			margin-top: 0;
		}

		.width-50 {
			width: 100% !important;
			margin-top: 4%;
		}

		.image {
			right: initial;
			width: 100%;
			height: auto;
			margin: 6% auto 0;
		}

		.content-box.form-wrapper {
			width: 90%;
			margin: 0% auto 5%;
		}
	}

	@media screen and (max-width: 991.98px) {
		.content-box.form-wrapper {
			width: 85%;
		}
	}

	@media screen and (max-width: 1199.98px) {}
}

.tutorial-backdrop {
	background: rgba(0, 0, 0, 0.75);
}

.dashboard-bg {
	background-color: $dashboard-bg-color;
}

.custom-material-position {
	position: absolute;
	top: 28px;
	left: 4px;
}

.custom-material-position-top {
	bottom: 28px;
	position: absolute;
	left: 4px;
}

/* as part of HA-UI changes */
.smooth-scroll {
	// -webkit-transition: all 0.3s ease;
	// -moz-transition: all 0.3s ease;
	// transition: all 0.3s ease;
}

.scroll-v {
	top: auto;
	transform: translateY(0px);
}

.scroll-o {
	// transform: translateY(-132px);
}

.sticky-stepper {
	top: 0;
	width: 100%;
	padding-top: 30px !important;
	position: fixed;
	transition: all 0.5s ease-in;
}

.sticky-stepper+.mat-horizontal-content-container {
	padding-top: 80px !important;
}

// For adding link to div
.email-link {
	cursor: pointer;
	font-weight: 600;
	color: $blue-color;

	&:hover {
		color: tint($blue-color, 20%);
	}
}

// to remove body scroll
.no-scroll {
	overflow: hidden;
}

.scroll-auto {
	overflow: auto !important;
}

.overflow-visible {
	overflow: visible !important;
}

.incentive-scroll {
	overflow: auto !important;

	.mat-mdc-dialog-container {
		padding: 0px;
	}
}

// Tooltip for Datepicker
.disabled-date-min::after,
.disabled-date-min::before,
.disabled-date-max::after,
.disabled-date-max::before,
.disabled-location-closed::after,
.disabled-location-closed::before,
.pv-disabled-date-max::after,
.pv-disabled-date-max::before {
	position: absolute;
	top: 60% !important;
	left: 50% !important;
	color: rgba(0, 0, 0, 0.87);
	display: none !important;
	transition: all ease 0.3s;
	z-index: 2 !important;
}

.disabled-date-min::before,
.disabled-date-max::before,
.disabled-location-closed::before {
	content: '';
	width: 10px !important;
	height: 10px !important;
	background: #fff;
	transform: rotate(45deg);
	margin-left: -5px;
	box-shadow: -2px -2px 5px -2px rgba(0, 0, 0, 0.4);
	margin-top: 6px;
}

.disabled-date-max::after {
	content: 'Too early to book for this date' !important;
	width: 170px !important;
	height: auto !important;
}

.disabled-date-min::after {
	content: 'Not allowed to book for this date' !important;
	width: 185px !important;
	height: auto !important;
}

.disabled-location-closed::after {
	content: 'Location is closed on this date' !important;
	width: 185px !important;
	height: auto !important;
}

.disabled-date-min::after,
.disabled-date-max::after,
.disabled-location-closed::after {
	transform: translateY(10px);
	font-size: 11px;
	margin-left: -20px;
	border-radius: 1px;
	padding: 12px;
	text-align: left;
	box-shadow:
		0 2px 4px -1px rgba(0, 0, 0, 0.2),
		0 4px 5px 0 rgba(0, 0, 0, 0.14),
		0 1px 5px 0 rgba(0, 0, 0, 0.12);
	background-color: #fff;
}

.mat-calendar-body {

	.mat-calendar-body-cell.disabled-date-min:hover,
	.mat-calendar-body-cell.disabled-date-max:hover,
	.mat-calendar-body-cell.disabled-location-closed:hover,
	.mat-calendar-body-cell.pv-disabled-date-max:hover {

		&::after,
		&::before {
			display: block !important;
		}
	}
}

// style for value prop
.value-p-0 {
	padding: 0px !important;
}

.hidden {
	display: none;
}

.text-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.password-wrapper {
	::ng-deep mat-progress-bar {
		width: 80% !important;
		margin-top: -25px !important;
		border-top: none !important;
	}

	::ng-deep rect {
		visibility: hidden;
	}

	.strength-wrapper {
		padding: 10px;
		box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
		margin-top: -20px;
	}
}

.ehe-modal,
.overlay-wrapper,
.wordpress-modal-wrapper,
.new-toaster-wrapper,
.full-overlay-wrapper {
	.close {
		position: absolute;
		height: 17px;
		width: 17px;
		top: 15px;
		right: 15px;
	}

	.close:before,
	.close:after {
		position: absolute;
		content: ' ';
		height: rem(26);
		width: 2px;
		background-color: black;
	}

	.close:before {
		transform: rotate(45deg);
	}

	.close:after {
		transform: rotate(-45deg);
	}
}

.modal-wrapper {
	.close-sm {
		height: 15px;
		width: 15px;
	}

	.close-sm:before,
	.close-sm:after {
		position: absolute;
		content: ' ';
		height: rem(18);
		width: 2px;
		background-color: black;
	}

	.close-sm:before {
		transform: rotate(45deg);
	}

	.close-sm:after {
		transform: rotate(-45deg);
	}
}

.full-overlay-wrapper {
	.close {
		top: -25px !important;
		right: 0 !important;
	}

	.close:before,
	.close:after {
		height: rem(28);
		width: 1.5px;
	}
}

.optimize {
	display: none;
}

.appointment-details-strip {
	background-color: $primary-font-color;
	color: $white-text;
}

.appointment-details-strip,
.reschedule-details-strip {
	height: auto;
	width: 100%;
	font-size: rem(16);
	display: block;

	.appt-details {
		padding: 0px 15px 10px 10px;

		.left {
			display: inline-block;
		}

		.step-heading {
			// font-size: 1.2rem;
			// line-height: 1.3rem;
			font-size: 1rem;
		}

		@media screen and (min-width: 800px) {
			// padding: 15px 0px;

			.step-heading {
				font-size: 1.8rem;
				line-height: 1.9rem;
			}
		}

		.right {
			display: inline-block;
			padding: 40px 0 40px 0;
			text-align: center;
			cursor: pointer;

			.star-rating {
				line-height: 24px;

				img {
					display: inline-block;
					color: $white-text;
					height: 24px;
					margin-top: -5px;
					margin-right: 10px;
				}

				.rating-message {
					display: inline-block;
				}
			}
		}
	}
}

.previous-arrow {
	position: absolute;
	left: 0;
	background-color: transparent;
}

.next-arrow {
	position: absolute;
	right: 0;
	background-color: transparent;
}

.previous-arrow:after,
.previous-arrow:before,
.next-arrow:after,
.next-arrow:before {
	margin: 5px;
	border-left-width: 2px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 20px;
	width: 20px;
	position: absolute;
	content: '';
	border: 0 solid currentColor;
	border-top-width: 2px;
	box-sizing: border-box;
}

.previous-arrow:after {
	transform: translateX(0) rotate(-45deg);
}

.previous-arrow:before {
	transform: translateX(0) rotate(-135deg);
}

.next-arrow:after {
	transform: translateX(0) rotate(135deg);
}

.next-arrow:before {
	transform: translateX(0) rotate(45deg);
}

.next-arrow {}

.inventory-teaser {
	margin: auto;
	padding: 10px;
	text-align: center;
	overflow: hidden;
	background-color: #fed25b !important;
	font-weight: 600;

	&.transition {
		transition: 0.3s ease-in;
	}

	mark {
		font-size: 1.1rem;
		background-color: #fed25b;
	}

	p {
		display: inline;
		margin: 0;
	}
}

.ul-align-left {
	ul {
		text-align: left;
		margin: 0;
		padding: 0;
		white-space: nowrap;
	}
}

.optimizeHidden {
	display: none;
	width: 0;
	height: 0;
	left: -1000px;
	top: -1000px;
}

.skeleton {
	position: relative;
	overflow: hidden;
	cursor: progress !important;
	cursor: wait !important;
	background: rgb(205, 205, 205);
	background: linear-gradient(90deg,
			rgba(205, 205, 205, 0.85) 0%,
			rgba(233, 233, 233, 0.85) 100%);

	&::before {
		display: block;
		content: "";
		height: 100%;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: rgb(255, 255, 255);
		background: linear-gradient(90deg,
				rgba(255, 255, 255, 0) 25%,
				rgba(255, 255, 255, 0.5) 50%,
				rgba(255, 255, 255, 0) 75%);
		transform-origin: center center;
		animation: left-to-right 2s ease-in-out 0s infinite normal forwards;

		@keyframes left-to-right {
			0% {
				opacity: 1;
				transform: translateX(-100%);
			}

			100% {
				opacity: 0.1;
				transform: translateX(100%);
			}
		}
	}
}