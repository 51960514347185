/* included global styles */
@use '@angular/cdk' as cdk;
@import 'ngx-toastr/toastr';
@import 'variables';
@import 'mixins';
@import 'helpers';
@import 'global';
@import 'typography';
@import 'buttons';
@import 'forms';
@import 'registration';
@import 'overrides';
@import 'responsiveness';
@include cdk.overlay();
