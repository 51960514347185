// Button Properties & Overrides
.button {
	cursor: pointer;

	&[disabled='disabled'],
	&:disabled {
		background-color: $disabled-color !important;
		color: tint($default-text-color, 65) !important;
		border: 1px solid $disabled-color !important;
	}
}

.mat-mdc-button {
	line-height: normal;
	cursor: pointer;
}

.mat-mdc-button.button {
	padding: 12px 26px;
	font-weight: 500;
	background-color: $primary-font-color;
	border: 1px solid $primary-font-color;
	text-transform: capitalize;
	border-radius: 60px;
	font-size: rem(16);
	line-height: normal;
	transition: all 0.2s ease;
	--mdc-text-button-label-text-color: #{$fill-button-text-color};

	&:hover,
	&:active {
		background-color: shade($primary-font-color, 20);
		border: 1px solid shade($primary-font-color, 20);
	}
}

.mat-mdc-button.button.secondary-btn {
	padding: 12px 26px;
	font-weight: 500;
	background-color: $white-text;
	color: $secondary-font-color;
	text-transform: capitalize;
	border-radius: 60px;
	font-size: rem(16);
	line-height: normal;
	--mdc-text-button-label-text-color: #{$secondary-font-color};
}

.mat-mdc-outlined-button.button {
	padding: 12px 26px;
	text-transform: capitalize;
	border-radius: 60px;
	font-weight: 500;
	color: $secondary-btn-border;
	border: 1px solid $secondary-btn-border;
	font-size: rem(16);
	line-height: normal;
	transition: all 0.2s ease;

	&:hover,
	&:active {
		background-color: $fill-button-color-hover;
		color: $secondary-btn-border;
		border-color: transparent;
	}
}

.mat-mdc-outlined-button.button.secondary-btn {
	padding: 12px 26px;
	text-transform: capitalize;
	border-radius: 60px;
	font-weight: 500;
	color: $secondary-btn-border;
	border: 1px solid $secondary-btn-border;
	font-size: rem(16);
	line-height: normal;

	&:hover,
	&:active {
		background-color: $fill-button-color-hover;
		color: $secondary-btn-border;
		border-color: transparent;
	}
}

.mat-mdc-outlined-button.button.secondary-btn.basic {
	padding: 12px 26px;
	text-transform: capitalize;
	border-radius: 60px;
	font-weight: 500;
	color: $secondary-btn-border;
	border: 1px solid rgba($secondary-btn-border, 0.2);
	font-size: rem(16);
	line-height: normal;

	&:hover,
	&:active {
		background-color: rgba(0, 0, 0, 0.02);
		color: $secondary-btn-border;
		border-color: transparent;
	}
}

.button.button-lg {
	padding: 16px 18px;
}

.button.button-medium {
	padding: 12px 26px;
}

.button.button-sm {
	padding-left: 20px !important;
	padding-right: 20px !important;
}

.button-group {
	.button {
		margin-left: 20px;
	}
}

.button-group-right {
	.button {
		margin-right: 20px;
	}
}

.l-button {
	display: inline-block;
	padding: 10px 20px;
	font-size: rem(18);
	font-weight: 600;

	i {
		margin-right: 10px;
	}

	&:hover {
		color: tint($primary-font-color, 20);
	}
}

.l-button-blue {
	color: $primary-font-color;
}

.btn-disabled {
	padding: 12px 26px;
	background-color: $gray-color;
	color: $fill-button-text-color;
	text-transform: capitalize;
	border-radius: 60px;
	font-size: rem(14);
	cursor: initial;
	position: relative;
}

button {
	&.ac-pushButton {
		// padding-right: 20px;
		// padding-left: 20px;
		// background: #d88d27;
		// border-radius: 50px;
		// font-size: 16px;
		// text-transform: capitalize;
		// color: white;
		// border: none;
		// line-height: 1.6rem;
		font-weight: 500;
		padding: 15px 40px;
		background-color: $primary-font-color;
		color: $fill-button-text-color;
		text-transform: capitalize;
		border-radius: 60px;
		font-size: rem(16);
		border: none;
		line-height: normal;
		transition: all 0.2s ease;
		cursor: pointer;

		&:hover {
			cursor: pointer;
			background-color: shade($primary-font-color, 20);
		}
	}
}

.reset-btn {
	color: #0155a5;
	background-color: transparent;
	border: none;
	justify-content: center;
	width: 100%;
	padding: 0 20px 20px 20px;
	cursor: pointer;
}

.carousel {
	.mat-mdc-icon-button {
		color: rgba(0, 0, 0, .54);
	}

	.mat-mdc-mini-fab.mat-primary {
		background-color: rgba(0, 0, 0, .54);
	}

	.mat-mdc-mini-fab[disabled][disabled] {
		background-color: rgba(0, 0, 0, 0.12);
	}
}