.login-wrapper,
.forgot-section-wrapper,
.reset-section-wrapper,
.create-account-wrapper,
.email-verification-wrapper,
.code-verification-wrapper,
.create-account-wrapper,
.request-email-wrapper,
.sso-redirection-wrapper {
	min-height: 100vh;
	background: #e5e5e5;
	background-image: url('/assets/images/ehe_color_cross.svg');
	background-repeat: no-repeat;
	background-position: bottom -415px right -500px;
	background-size: 100% 140%;

	@media screen and (max-width: 767.98px) {
		background: #e5e5e5;
	}

	img {
		margin-bottom: 20px;
	}

	.font-red {
		color: red;
	}

	.footer-section {
		position: fixed;
		left: 0;
		bottom: 0;
		width: 100%;
		text-align: center;
	}
}

.content-box {
	&.form-wrapper {
		background-color: $white-background-color;
		padding: 40px 30px;
		width: 420px;
		margin: 0 auto;

		@media screen and (max-width: 991.98px) {
			width: 100%;
		}

		@media screen and (max-width: 767.98px) {
			width: 100%;
		}
	}

	margin-bottom: 40px;

	.auth-content {
		width: 80%;
		margin: 28px auto 30px;
	}

	// padding: 5% 10% 2%;
	// background-color: $transparent-white-background;
	.rows {
		display: block;
		@include clearfix;
	}

	.button {
		// margin-top: 30px;
		margin-bottom: 0;
		padding-left: 60px;
		padding-right: 60px;
	}

	.button-medium {
		padding-left: 40px;
		padding-right: 40px;
	}

	mat-form-field {
		width: 100%;
	}

	.rows.links {
		margin-top: 30px;
		justify-content: space-between;

		ul {
			text-align: center;
			margin-bottom: 0;
			width: 100%;
			padding-left: 0;

			li {
				display: inline-block;
				list-style: none;

				a {
					display: inline-block;
					color: $text-color;
					font-size: rem(14);
				}

				&:after {
					content: '|';
					color: $text-color;
					padding: 0 8px;
				}

				&:last-child {
					&:after {
						display: none;
					}
				}
			}

			@media screen and (max-width: 767.98px) {
				li {
					display: block;
					margin-bottom: 8px;

					&:after {
						display: none;
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.small-text {
		margin-bottom: 20px;
	}

	.divider {
		display: none;
		margin-bottom: 20px;
		margin-top: 60px;

		.placeholder {
			svg {
				fill: $blue-color;
				height: 90px;
				margin-bottom: 20px;
			}
		}

		p {
			font-size: rem(14);
			margin-bottom: 20px;
		}
	}

	.adjustment-radio-btn {
		padding-top: 20px;
		display: flex;
		justify-content: space-between;

		.mat-mdc-radio-button {
			margin: 0 10px;
		}
	}

	.resend-container {
		margin-top: 30px;

		.text {
			margin-bottom: 4px;
		}
	}

	.email-heading {
		padding: 20px;
	}
}

.code-verification-wrapper {
	.link {
		color: $primary-font-color;
		cursor: pointer;
	}
}

.login-variation-wrapper {
	min-height: 100vh;
	background-image: url('/assets/images/pulse_blue_cross.svg');
	background-repeat: no-repeat;
	background-position: bottom -120px right -200px;
	background: linear-gradient(90deg, #ffffff 50%, #2b678c 50%);

	@media screen and (max-width: 767.98px) {
		background: linear-gradient(to bottom, #ffffff 50%, #2b678c 50%);
	}
}
