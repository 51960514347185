$header-color: #0d3d60;
$header-text-color: #e1e1e1;
$header-active-color: #f7bc0d;
$header-badge-color: #ea5906;
$major-background-color: #edf4f5;
$global-text-color: #333333;

$fill-button-color: #ffc608;
$fill-button-color-hover: #ffc700;
$fill-button-text-color: #ffffff;
$link-text-color: #1a5fab;
$major-heading-color: #51919a;
$major-head-border-color: #51919a;
$placeholder-text-color: #757575;
$text-color: #242221;
$cancel-button-color: #c8c8c8;
$content-box-color: #ffffff;
$error-message-text-color: #f44336;
$badge-background-color: #ea5906;

//theme
$primary-font-color: #2b678c;
$secondary-font-color: rgb(117, 117, 117);
$white-background-color: #ffffff;
$transparent-white-background: rgba(255, 255, 255, 0.702);
$white-text: #ffffff;
$tag-color: rgb(255, 120, 0);
$active-tab-color: #febe14;
$active-menu-color: #0155a5;
$submenu-background: #0155a5;
$progressbar-color: #8dc63f;
$disabled-color: #dddddd;
$normal-text-color: #333333;
$dashboard-bg-color: #f2f6fa;
$footer-bg-color: #d7d8da;
$secondary-btn-border: #505153;

//scheduling
$light-background-color: rgb(229, 238, 246);
$border-color: #e2e2e2;
$left-outline: #b2b2b2;
$appointment-info-outline: #c7c6c6;
$wizard-active-background: #51919a;
$wizard-complete-background: #a0c5c9;
$wizard-splitter: #ffffff;
$wizard-background: #c8c8c8;
$wizard-active-text-color: #ffffff;
$wizard-text-color: #000000;
$utility-block-background-color: #EBF4FF;
$booking-summary-block-background-color: #EBF4FF;

//alertbox
$warning-title-color: rgb(253, 193, 88);
$success-title-color: rgb(145, 216, 120);
$alert-title-color: rgb(253, 115, 115);
$event-banner-color: #fffae6;
$event-banner-text-color: #d0c06e;
$panel-text-color: #bebcbe;

//toast
$toast-warning: #ff7800;
$toast-success: #4ea81e;
$toast-error: #ff4c52;
$toast-info: #1b569f;

// Updated Variables for new theme

// borders
$aside-border-color: #cdcdcd;
$module-border-color: #cfcfcf;
$panel-border-color: #dcdcdc;

// text
$blue-color: #0053a7;
$gray-color: #888888;
$gray-heading-color: #848484;
$default-text-color: #171717;
$icon-button-color: #757575;
$table-stripe-odd: #f7f7f7;
$table-stripe-even: #cccccc;

$highlight-mail: #f9f9f9;
$car-serice-bg: #fcf5e0;

// Wellness
$border-color-wellness: #c8c8c8;

// Referrals - Success toaster
$success-bg-color: #ceefca;
$success-text-color: #3da226;

// Tutorial
$ADA-blue: #0155a5;
$tutorial-border-black: #000000;
$tutorial-padding-sides: 8%;
$tutorial-padding-top: 5%;
$tutorial-height: 400px;
$tutorial-width: 768px;

// New wireframe updated variables
$new-border-color: rgb(220, 220, 220);

$health-coach-quiz-card: rgb(26, 95, 171);
$procedure-select-box-icon-color: #0053a7;
$gray-border: #979797;
$gray-four: rgba(0, 0, 0, 0.87);
$gray-three: rgba(0, 0, 0, 0.54);
$gray-two: rgba(0, 0, 0, 0.15);
$gray-one: rgba(0, 0, 0, 0.12);
$gray-five: rgba(0, 0, 0, 0.25);
$gray-light: #e7e3e3;
$new-blue: #2e58af;
$new-bluehover: #1c4699;
$verylight-blue: rgba(0, 0, 255, 0.05);
$location-color: #242322;
$hover-blue: #3f51b5;
$new-blue-button: #1c60ab;
$new-yellow-button: #febe14;
$bottom-liner: #d8d8d8;

//simple-progressbar
$progress-bar-text: rgba(36, 35, 34, 0.86);
$progress-bar-outer: rgba(36, 35, 34, 0.1);
$progress-bar-inner: #66b2bc;
$green-one: #66b2bc;
$green-two: #28a428;
$black-shade-one: rgba(0, 0, 0, 0.86);
$red-color: #b00020;
$blue-border-color: #1e90e1;

//password strength indicator
$green-check: #1d9c00;
$red-exclamation: #b1001d;
$warning-color: #ffc80e;
$progress-bar-default: #dedede;
$progress-bar-warning: #fff7db;

$cream-banner-color: rgba(255, 200, 14, 0.2);
$incentive-banner-color: rgba(255, 200, 14, 0.15);

$light-gray: #f6f6f6;
$placeholder-text-color-gray: rgba(0, 0, 0, 0.5);
$ha-bg-color: rgba(216, 216, 216, 0.15);
$ha-form-bg-color: rgba(0, 0, 0, 0.04);

// pulse virtual
$pv-primary: #0d3d60;
$pv-secondary: #2b678c;
$pv-trademark-yellow: #ffc80e;
$pv-background-yellow: #febe14;
$pv-background-grey: #fafafa;
$pv-btn-primary: #1c60ab;
$pv-text-black: #000000;
$pv-icon-blue: #0e3b5e;
$pv-text-registration-form-header: 32px;
$pv-font-header: (
	font-size: 32px,
	line-height: 39px,
);
