@import '_generic';

@media screen and (max-width: 991.98px) {
	.ehe {
		// min-height: calc(100vh - 100px);
	}

	// .mat-horizontal-stepper-header-container {
	//   margin-bottom: 0 !important;
	// }
	.mat-expansion-panel-header-title.scheduling {
		span:nth-child(2) {
			font-size: rem(11);
			padding-top: 5px;
		}

		span:last-child {
			display: none;
		}

		span:first-child {
			font-size: rem(16);
		}
	}

	.mat-modal-wrapper {
		width: 100%;
		margin: 0 auto;
	}

	// General Wrapper
	.general-wrapper {
		.g-left {
			flex: 0 0 100% !important;
			max-width: 100% !important;
		}

		.g-right {
			flex: 0 0 100% !important;
			max-width: 100% !important;

			.left-outline {
				margin-top: 20px;
				border-left: none !important;
				padding-left: 0px !important;
			}
		}
	}

	.hide-on-small {
		display: block !important;
	}

	.hide-on-small-flex {
		display: flex !important;
	}

	.find-appointment-wrapper {
		.procedure-eligibility-block {
			.procedure-select-box {
				/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
				.mat-form-field-wrapper {
					/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
					.mat-form-field-flex {
						/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
						.mat-form-field-infix {
							padding-bottom: 5px !important;
						}
					}
				}
			}
		}
	}

	.pt-30 {
		padding-top: 30px;
	}

	.appointment-slot-calendar-wrapper {
		.row {
			.col-o {
				min-width: 50% !important;
			}
		}
	}
}

.hide-on-large {
	display: none !important;
}

.hide-on-small {
	display: block !important;
}

.hide-on-large-flex {
	display: none !important;
}

.hide-on-small-flex {
	display: flex !important;
}

@media screen and (max-width: 767.98px) {
	.new-toaster-wrapper {
		top: unset !important;
		left: 0 !important;
		right: unset !important;
		bottom: 0vh !important;
		width: 100vw !important;
		position: fixed !important;

		.title {
			font-size: rem(16) !important;
		}

		.content {
			padding: 10px !important;

			.message {
				display: none !important;
			}
		}

		.left-border {
			border-radius: 0 !important;
		}

		@keyframes toast {
			0% {
				bottom: 0vh;
			}

			100% {
				bottom: 0vh;
			}
		}

		.close {
			top: 10px;
			right: 10px;
		}

		.close:before,
		.close:after {
			height: rem(20);
		}
	}

	.health-history-wrapper {
		height: 100% !important;

		.left-outline {
			border-left: 0px !important;
			padding-left: 0px !important;
		}

		.doc-info {
			flex-basis: calc(100% - 30px);
		}
	}

	.d-md-none.on-small-screen.h-h-title {
		color: $blue-color;
		font-weight: 600;
		font-size: rem(21);
		margin-bottom: 20px;
	}

	.mat-modal-wrapper {
		width: 100%;
		margin: 0 auto;
	}

	.mat-step-label-title {
		display: none;
	}

	.hide-on-large {
		display: block !important;
	}

	.hide-on-small {
		display: none !important;
	}

	.hide-on-large-flex {
		display: flex !important;
	}

	.hide-on-small-flex {
		display: none !important;
	}

	.find-appointment-wrapper {
		.appt-title {
			text-align: center;
			padding: 20px 0 10px 0;
		}

		.procedure-eligibility-block {
			display: none !important;
		}

		.mob-greeting {
			text-align: center;

			.greeting-wrapper {
				margin-top: -40px;
			}
		}

		.procedure-eligibility-block,
		.wp-links {
			justify-content: center !important;
			flex-direction: column-reverse;

			.star-rating {
				padding: 15px;
			}

			.buttons {
				display: flex;
				justify-content: space-evenly;
			}
		}
	}

	.scheduling-wrapper {
		.simple-progress-wrapper {
			width: 100% !important;
			margin-top: -30px;

			.outer-progress {
				width: 100% !important;
				border-radius: 0 !important;
			}

			.inner-progress {
				border-radius: 0 !important;
			}
		}

		.g-right {
			padding: 0 !important;
		}
	}

	.mat-horizontal-content-container {
		padding: 1px !important;
		overflow-y: auto !important;
	}

	.appointment-slot-calendar-wrapper {
		.row {
			.col-o {
				min-width: 60% !important;
			}
		}
	}

	.toaster-wrapper {
		left: 0 !important;
	}
}

@media screen and (max-width: 991.98px) {
	.hide-on-small {
		display: none !important;
	}

	.hide-on-large {
		display: block !important;
	}

	//
	.hide-on-large-flex {
		display: flex !important;
	}

	.hide-on-small-flex {
		display: none !important;
	}

	.find-appointment-wrapper {
		.appt-title {
			text-align: center;
			padding: 20px 0 10px 0;
		}

		.procedure-eligibility-block {
			display: none !important;
		}

		.mob-greeting {
			text-align: center;

			.greeting-wrapper {
				margin-top: -40px;
			}
		}

		.appt-title-header {
			justify-content: space-evenly !important;
		}

		.procedure-eligibility-block,
		.wp-links {
			justify-content: center !important;
			flex-direction: column-reverse;

			.star-rating {
				padding: 15px;
			}

			.buttons {
				display: flex;
				justify-content: space-evenly;
			}
		}
	}

	.scheduling-wrapper {
		.simple-progress-wrapper {
			width: 100% !important;
			margin-top: -30px;

			.outer-progress {
				width: 100% !important;
				border-radius: 0 !important;
			}

			.inner-progress {
				border-radius: 0 !important;
			}
		}

		@media (min-width: 768px) and (max-width: 991.98px) {
			.g-right {
				padding: 0 !important;
			}
		}
	}

	.global-sidebar-nav {
		padding: 15px 15px 0 !important;
		margin-bottom: 0 !important;

		li {
			padding: 6px 12px !important;
			color: $white-text !important;
			text-align: left !important;
			border-bottom: 1px solid tint($header-color, 10) !important;
			font-size: rem(16) !important;

			&:after {
				float: right;
			}

			background-color: $header-color;
		}
	}

	.global-sidebar-on-small-screen-wrapper,
	.mobile-inner-menu-container,
	.responsive-sub-menu-wrapper {
		ul.global-sidebar-on-small-screen,
		.mobile-inner-menu {
			display: flex;
			flex-direction: column;

			li.global-sidebar-on-small-screen-li,
			li.mobile-sub-menu {
				display: none;
				text-align: center;
				order: 2;
				-webkit-order: 2;

				&.active {
					display: block;
					font-weight: 600;
					order: 1;
					-webkit-order: 1;

					&::after {
						font-family: 'Font Awesome 5 Free';
						content: '\f107';
						margin-left: 10px;
					}
				}
			}

			&:focus {
				li.global-sidebar-on-small-screen-li {
					display: block;
				}
			}
		}

		.wellness-mobile-menu {
			display: none;

			&.active {
				display: block;
			}
		}
	}

	.sso-redirection-wrapper {
		.split {
			display: block !important;

			button.button {
				width: 100%;
				white-space: inherit;
				margin-left: 0 !important;
				margin-right: 0 !important;
			}
		}
	}

	.procedure-drop-down {
		width: 100% !important;
	}

	.toaster-wrapper {
		left: 0 !important;
	}
}

.responsive-sub-menu-wrapper {
	margin: 0 auto;
}

@media screen and (max-width: 575.98px) {
	.top-spacing-on-small-device {
		margin-top: 15px;
	}

	.dashboard-wrapper {
		button.ac-pushButton {
			font-size: rem(12);
			padding: 15px 20px;
			max-width: 100%;
		}
	}

	.procedure-drop-down {
		width: 100% !important;
	}
}

/** iPad Pro Portrait & iPad Landscape **/
@media screen and (min-width: 768px) and (max-width: 1199.98px) {
	@media (orientation: portrait) {
		.sso-redirection-wrapper {
			background-position: right 50% top 0px;
		}

		// iPad Pro
		.toaster-wrapper {
			left: 30vw !important;
			top: 40vw !important;
		}
	}

	.auth-header-wrapper {
		.nav-menu {
			li {
				margin: 0 30px 0 0 !important;
			}
		}

		.profile-name {
			display: none !important;
		}

		.m-m {
			left: 35% !important;
		}
	}

	.find-appointment-wrapper {
		.mob-date-section {
			.date {
				font-size: rem(26) !important;
			}
		}

		.mob-f-16 {
			font-size: rem(18) !important;
		}

		.eligible-date {
			font-size: rem(18) !important;
		}
	}

	@media (orientation: landscape) {
		.find-appointment-wrapper {
			.stroked {
				.slot-time {
					width: initial !important;
					font-size: rem(12) !important;
				}

				.slot-am-pm {
					font-size: rem(12) !important;
				}

				.slot-rectangle {
					width: 94px !important;
					height: 30px !important;
				}

				.display-time {
					padding: 0 0 0 5px !important;
				}
			}

			.date-section {
				font-size: rem(22) !important;
				line-height: 24px !important;

				.date {
					margin-left: 10px !important;
				}

				.right-arrow {
					margin-right: 20px !important;
				}
			}

			.main-content {
				width: 85% !important;
			}
		}
	}
}

@media screen and (max-width: 992px) and (max-width: 1199.98px) {
	.appointment-details-wrapper {
		.appointment-info {
			width: 100% !important;

			.g-content {
				padding: 15px !important;
			}
		}
	}
}

@media screen and (min-width: 1200px) {
	.min-width-14 {
		min-width: 14%;
	}
}

@media screen and (max-height: 770px) {
	.results-to-share-dropdown {
		left: 0;
		position: static;
		top: 0;
	}
}
