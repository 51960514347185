[class^='mat-']:not(.material-icons),
[class^='mdc-']:not(.material-icons) {
	font-family: 'Proxima Nova', sans-serif !important;
	letter-spacing: 0px !important;
}

$Input-Accent-Color: $blue-color;

:root {
	--mdc-checkbox-selected-focus-icon-color: #{$Input-Accent-Color};
	--mdc-checkbox-selected-hover-icon-color: #{$Input-Accent-Color};
	--mdc-checkbox-selected-icon-color: #{$Input-Accent-Color};
	--mdc-checkbox-selected-pressed-icon-color: #{$Input-Accent-Color};
	--mdc-checkbox-selected-focus-state-layer-color: #{$Input-Accent-Color};
	--mdc-checkbox-selected-hover-state-layer-color: #{$Input-Accent-Color};
	--mdc-checkbox-selected-pressed-state-layer-color: #{$Input-Accent-Color};
}

.cdk-overlay-container {
	z-index: 9999999999 !important;
}

.ngx-spinner-overlay {
	z-index: 9999999999 !important;
}

.mat-mdc-radio-button.mat-accent {
	--mdc-radio-selected-focus-icon-color: #{$Input-Accent-Color};
	--mdc-radio-selected-hover-icon-color: #{$Input-Accent-Color};
	--mdc-radio-selected-icon-color: #{$Input-Accent-Color};
	--mdc-radio-selected-pressed-icon-color: #{$Input-Accent-Color};
	--mat-radio-checked-ripple-color: #{$Input-Accent-Color};
}

.mat-mdc-dialog-container {
	--mdc-dialog-container-color: transparent;
}

.mat-mdc-option .mdc-list-item__primary-text {
	white-space: nowrap !important;
	flex-grow: 1;
	overflow: hidden;
	text-overflow: ellipsis;
}

.mat-mdc-form-field {
	.mdc-text-field--filled {
		padding: 0 !important;

		&:not(.mdc-text-field--disabled) {
			background-color: unset !important;
		}

		.mat-mdc-floating-label {
			top: 36px !important;
			margin-bottom: 0 !important;
		}

		.mdc-floating-label--float-above {
			transform: translateY(-165%) scale(0.75) !important;
		}

		.mat-mdc-form-field-focus-overlay {
			display: none !important;
		}
	}

	.mat-mdc-form-field-hint-wrapper,
	.mat-mdc-form-field-error-wrapper {
		padding: 0 !important;
	}

	[class*='--no-label'] {
		.mat-mdc-form-field-infix {
			min-height: unset !important;
			padding-top: 10px !important;
			padding-bottom: 5px !important;
		}

		.mdc-icon-button {
			padding: 0 !important;
			height: 30px !important;
			width: 30px !important;
		}

		&[class*='--disabled'] {
			background-color: unset !important;

			.mdc-line-ripple::before {
				border-bottom-color: rgba(0, 0, 0, 0.2) !important;
			}
		}
	}
}

.mdc-label {
	margin-bottom: unset;
}

.location-select {
	min-width: fit-content;
}

.mat-mdc-checkbox .cdk-overlay-container,
.cdk-overlay-pane {
	z-index: 9999 !important;
	max-width: none !important;
	/* it is set for the proper view in mobile */
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
.mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
	border-color: #{$Input-Accent-Color};
}

mat-icon {
	font-family: 'Material Icons' !important;
}

.mat-mdc-chip.mat-mdc-standard-chip {
	background-color: $white-background-color;
	color: $text-color;
	border: 1px solid $border-color;
}

.mat-mdc-radio-button.mat-accent .mat-radio-inner-circle,
.mat-mdc-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
	background-color: #{$Input-Accent-Color};
}

.new-ha-wrapper {
	.mat-mdc-form-field {
		margin-bottom: 5px;

		.mdc-text-field--filled {
			padding: 0 10px !important;

			&:not(.mdc-text-field--disabled) {
				background-color: whitesmoke !important;
			}
		}

		.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) {
			.mat-mdc-form-field-infix {
				padding-top: 28px;
				padding-bottom: 11px;
			}
		}
	}

	.mat-mdc-slider {
		--mdc-slider-handle-color: #{$Input-Accent-Color};
		--mdc-slider-focus-handle-color: #{$Input-Accent-Color};
		--mdc-slider-hover-handle-color: #{$Input-Accent-Color};
		--mdc-slider-active-track-color: #{$Input-Accent-Color};
		--mdc-slider-inactive-track-color: #{$Input-Accent-Color};
		--mdc-slider-with-tick-marks-active-container-color: #fff;
		--mdc-slider-with-tick-marks-inactive-container-color: #{$Input-Accent-Color};
		--mat-mdc-slider-ripple-color: #{$Input-Accent-Color};
		--mat-mdc-slider-hover-ripple-color: rgba(63, 81, 181, 0.05);
		--mat-mdc-slider-focus-ripple-color: rgba(63, 81, 181, 0.2);

		width: calc(100% - 40px);
	}
}

/* to find appointment wrapper inline form */
.find-appointment-wrapper {
	.inline-form {

		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-wrapper {

			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			.mat-form-field-flex {
				background: #fff;
				padding: 12px 10px 5px 10px;
				border: 1px solid $border-color;

				/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
				.mat-form-field-infix {
					border: 0px;
					padding-top: 0px;
				}
			}

			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			.mat-form-field-underline {
				background-color: transparent;
			}
		}
	}

	.mat-mdc-form-field.mat-focused {

		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-ripple {
			display: none;
		}

		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-flex {
			border: 1px solid $blue-color;
		}
	}
}

.procedure-select-box {

	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.mat-form-field-wrapper {
		padding-bottom: 0;

		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-flex {
			background: #fff;
			padding: 10px;
			border: 1px solid $border-color;

			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			.mat-form-field-infix {
				border: 0px;
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}

	.select-box-v2 {
		cursor: pointer;

		.selected-procedure-v2 {
			color: white !important;
			left: 0 !important;
			text-transform: capitalize;
		}

		i {
			position: relative !important;
			right: -15px !important;
			top: 0 !important;
			color: white !important;
		}
	}
}

.mob-procedure-select-box {
	.procedure-select-box {

		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-wrapper {

			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			.mat-form-field-flex {

				/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
				.mat-form-field-infix {
					padding-bottom: 8px !important;
				}
			}
		}

		.procedure-text,
		.selected-procedure {
			font-size: rem(18px) !important;
			line-height: rem(18px) !important;
			color: $default-text-color !important;
		}
	}
}

.mob-procedure-select-box-new-ui {
	.procedure-select-box {

		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-wrapper {

			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			.mat-form-field-flex {

				/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
				.mat-form-field-infix {
					padding-bottom: 8px !important;
				}
			}
		}

		.procedure-text,
		.selected-procedure {
			font-size: rem(16px) !important;
			line-height: rem(16px) !important;
			color: $default-text-color !important;
		}
	}
}

/* Referral stepper */
.mat-stepper-horizontal {
	&:not(.default-styling) {
		.mat-horizontal-stepper-header-container {
			text-align: center;
			overflow: hidden;
			padding-bottom: 30px;
			background-color: #fff;
			z-index: 2;

			.mat-horizontal-stepper-header {
				text-decoration: none;
				outline: none;
				display: block;
				float: left;
				font-size: rem(12);
				line-height: 36px;
				padding: 0 10px 0 10px;
				position: relative;
				background: $wizard-background;
				color: $wizard-text-color;
				transition: background 0.5s;
				height: 50px;
				width: 100%;
				margin-right: -3px;

				.mat-step-icon-state-number,
				.mat-step-icon {
					display: none;
				}

				.mat-step-label {
					text-align: center;
					line-height: 50px;
					color: $white-text;
					font-weight: 600;
				}
			}

			.mat-horizontal-stepper-header:first-child {
				padding-left: 0px;
			}

			.mat-horizontal-stepper-header:first-child::before {
				left: 14px;
			}

			.mat-horizontal-stepper-header:last-child {
				border-radius: 0 5px 5px 0;
				padding-right: 20px;
			}

			.mat-horizontal-stepper-header:last-child::after {
				content: none;
			}

			.mat-horizontal-stepper-header:last-child::before,
			.mat-horizontal-stepper-header:nth-of-type(2):before {
				content: '';
				width: 50px;
				height: 50px;
				left: -32px;
				transform: rotate(45deg);
				line-height: 20px;
				position: absolute;
				top: 0;
				font-weight: 600;
				background: $wizard-splitter;
			}

			.mat-horizontal-stepper-header::after {
				content: '';
				position: absolute;
				top: -1px;
				right: -2px;
				width: 58px;
				height: 52px;
				-webkit-transform: scale(0.707) rotate(45deg);
				transform: scale(0.707) rotate(45deg);
				z-index: 1;
				border-radius: 0 1px 0 49px;
				background: $wizard-background;
				transition: background 0.5s;
				box-shadow: 15px -15px 0 11px $wizard-splitter;
			}

			.mat-horizontal-stepper-header[ng-reflect-selected='true'] {
				color: $wizard-active-text-color;
				background: $wizard-active-background;

				&:before {
					color: $wizard-active-text-color;
				}

				&:after {
					color: $wizard-active-text-color;
					background: $wizard-active-background;
				}
			}

			.mat-horizontal-stepper-header:hover {
				color: $wizard-active-text-color;
				background: $wizard-active-background;
			}

			.mat-horizontal-stepper-header:hover::before {
				color: $wizard-active-text-color;
			}

			.mat-horizontal-stepper-header:hover::after {
				color: $wizard-active-text-color;
				background: $wizard-active-background;
			}
		}
	}

	&.default-styling {
		&.wrap-labels {
			.mat-step-text-label {
				text-overflow: inherit;
				white-space: normal;
			}
		}
	}
}

.pulse-virtual-stepper {
	&.mat-stepper-horizontal,
	&.mat-stepper-vertical {
		background-color: transparent;
	}

	.mat-horizontal-stepper-header {
		margin: 0 20px;
		max-width: 225px;

		&:first-child {
			padding-left: 0;
		}

		&:last-child {
			padding-right: 0;
		}
	}

	&.mat-stepper-vertical {
		.mat-step-header {
			padding-left: 15px;
			padding-right: 15px;
		}

		.mat-step-header,
		.mat-step-text-label{
			overflow-x: auto;
			text-overflow: initial;
		}
		
		.mat-step-label {
			white-space: inherit !important;
		}

		.mat-vertical-content-container {
			margin-left: 26px;
		}
	}

	.mat-stepper-horizontal-line {
		border-top-style: dashed;
	}

	.mat-stepper-vertical-line::before {
		border-left-style: dashed;
	}

	.mat-vertical-stepper-content {
		height: 0;
	}

	.mat-step-header {
		cursor: auto;

		&[ng-reflect-state='done']+.mat-stepper-horizontal-line {
			border-top-style: solid;
		}

		&[ng-reflect-state='done']+.mat-stepper-vertical-line::before {
			border-left-style: solid;
		}

		&[ng-reflect-state='done'] {
			.mat-step-label.mat-step-label-active {
				color: $gray-three;
			}
		}

		&[ng-reflect-selected='true'], &[aria-selected='true']{
			@media screen and (min-width: 800px) {
				background-color: #F8FDFF !important;
				border-top: solid;
			}
		}

		&:hover:not([aria-disabled]) {
			background: transparent;
		}

		.mat-step-icon {
			&:not(.mat-step-icon-selected):not(.mat-step-icon-state-done) {
				background-color: transparent;
				border: 1px solid;
				color: $pv-primary;
			}

			&.mat-step-icon-state-done {
				background-color: $gray-two;
			}

			&.mat-step-icon-selected {
				background-color: $pv-primary;
			}
		}
	}
}

.pv-confirmation:not(.mobile) {
	.info-banner-wrapper {
		margin-left: -30px;
		margin-right: -30px;
	}
}

.health-history-wrapper {
	.del-button-medic {
		position: absolute;
		right: 15px;
		top: 14px;
	}

	.mat-horizontal-content-container {
		padding: 1px 1px 0;

		&.modal-open {
			overflow: hidden !important;
		}
	}

	.disabled {
		cursor: default;
		color: $secondary-font-color;
	}
}

.referral-wrapper {
	.mat-horizontal-content-container {
		padding: 1px !important;
		/* height: calc( 100vh - 300px); */
		/* overflow-y: auto !important; */
	}
}

.health-history-wrapper,
.referral-wrapper {
	.w-100 {
		width: 100%;
	}

	.t-r {
		text-align: right;
	}

	.h-w-100 {
		.mat-mdc-form-field {
			width: 100% !important;
		}
	}

	.cur-health-wrapper {
		.toggle-select {
			position: relative;
			top: -15px;
		}

		.toggle-select-text {
			position: relative;
			top: 8px;
		}
	}

	.allergies-wrapper .custom-radio-btn-wrapper:hover {
		background: #fff !important;
		border-color: #fff !important;
	}

	.ha-align-center {
		position: relative;
		top: 15px;
	}

	.ha-tool-inf {
		top: 0px !important;
		width: 32px;
		position: relative;
	}

	.ha-align-center-2 {
		position: relative;
		top: 15px;
	}

	.ha-height-fix {

		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-wrapper {
			bottom: -1.475em;
		}
	}

	.ha-horizontal-scroll {

		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-flex {
			height: 60px;
		}

		/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
		div.mat-chip-list-wrapper {
			display: flex;
			flex-wrap: nowrap;
			overflow-x: auto;

			.mat-mdc-chip {
				flex: 0 0 auto;
			}
		}
	}

	.ha-pad-25 {
		padding-bottom: 25px;
	}

	.ha-t-fix {
		padding-top: 20px;
	}

	.to-bottom {
		align-self: flex-end;
		margin-bottom: 30px;
	}

	button.button {
		margin-right: 20px;
	}

	.allergy-wrapper {
		button.button {
			margin-right: 0 !important;
		}
	}

	.step-0 .mat-horizontal-stepper-header-container {
		.mat-horizontal-stepper-header:first-child {
			color: $wizard-active-text-color;
			background: $wizard-active-background;
		}

		.mat-horizontal-stepper-header:first-child::before {
			color: $wizard-active-text-color;
		}

		.mat-horizontal-stepper-header:first-child::after {
			color: $wizard-active-text-color;
			background: $wizard-active-background;
		}
	}

	.step-1 .mat-horizontal-stepper-header-container {
		.mat-horizontal-stepper-header:first-child {
			color: $wizard-active-text-color;
			background: $wizard-complete-background;
		}

		.mat-horizontal-stepper-header:first-child::before {
			color: $wizard-active-text-color;
		}

		.mat-horizontal-stepper-header:first-child::after {
			color: $wizard-active-text-color;
			background: $wizard-complete-background;
		}

		.mat-horizontal-stepper-header:nth-child(3) {
			color: $wizard-active-text-color;
			background: $wizard-active-background;
		}

		.mat-horizontal-stepper-header:nth-child(3)::before {
			color: $wizard-active-text-color;
		}

		.mat-horizontal-stepper-header:nth-child(3)::after {
			color: $wizard-active-text-color;
			background: $wizard-active-background;
		}
	}

	.step-2 .mat-horizontal-stepper-header-container {
		.mat-horizontal-stepper-header:first-child {
			color: $wizard-active-text-color;
			background: $wizard-complete-background;
		}

		.mat-horizontal-stepper-header:first-child::before {
			color: $wizard-active-text-color;
		}

		.mat-horizontal-stepper-header:first-child::after {
			color: $wizard-active-text-color;
			background: $wizard-complete-background;
		}

		.mat-horizontal-stepper-header:nth-child(3) {
			color: $wizard-active-text-color;
			background: $wizard-complete-background;
		}

		.mat-horizontal-stepper-header:nth-child(3)::before {
			color: $wizard-active-text-color;
		}

		.mat-horizontal-stepper-header:nth-child(3)::after {
			color: $wizard-active-text-color;
			background: $wizard-complete-background;
		}

		.mat-horizontal-stepper-header:nth-child(5) {
			color: $wizard-active-text-color;
			background: $wizard-active-background;
		}

		.mat-horizontal-stepper-header:nth-child(5)::before {
			color: $wizard-active-text-color;
		}

		.mat-horizontal-stepper-header:nth-child(5)::after {
			color: $wizard-active-text-color;
			background: $wizard-active-background;
		}
	}

	.custom-radio-button-set-wrapper {
		._label {
			p {
				margin-bottom: 0;
			}

			.mat-form-field-hint-spacer {
				flex: 0;
			}
		}
	}
}

.referral-wrapper {
	.step-0 .mat-horizontal-stepper-header-container {

		.mat-horizontal-stepper-header:nth-child(3),
		.mat-horizontal-stepper-header:last-child {
			pointer-events: none;
		}
	}

	.step-1 .mat-horizontal-stepper-header-container {

		.mat-horizontal-stepper-header:last-child,
		.mat-horizontal-stepper-header:first-child {
			pointer-events: none;
		}
	}

	.step-2 .mat-horizontal-stepper-header-container {

		.mat-horizontal-stepper-header:first-child,
		.mat-horizontal-stepper-header:nth-child(3) {
			pointer-events: none;
		}
	}
}

.container.custom-radio-btn-wrapper:hover {
	/* background: #F8F8F8; */
	/* border-color: #F8F8F8; */
	/* padding-right: 0 !important; */
}

#bodyMap,
table tr td {
	.container.custom-radio-btn-wrapper:hover {
		background: inherit;
		border-color: $white-background-color;
		/* padding-right: 0 !important; */
	}

	.container.custom-radio-btn-wrapper {
		min-height: auto;
		border: 0;
		margin-bottom: 0;
	}
}

.w-40 {
	width: 40%;
}

.mat-calendar-abbr {
	text-decoration: none !important;
}

.health-history-wrapper {
	.w-80 {
		.mat-mdc-form-field {
			width: 80% !important;
		}
	}

	.text-wrap {
		max-width: 80%;
	}

	.ha-radio-button {
		.ha-right-align {
			margin-left: auto !important;
		}
	}

	/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
	.mat-slider-ticks {
		opacity: 1 !important;
	}

	.section-header.mat-expansion-panel-header-title {
		font-size: rem(18);
		color: $blue-color;
		font-weight: 700;
	}
}

.custom-radio-btn-wrapper {
	margin-bottom: 20px;
	padding-left: 30px !important;
	padding-right: 32px !important;
	margin-left: -26px;
	width: calc(100% + 52px);
	border: 10px solid transparent;
	min-height: 50px;

	.col,
	.col-md-auto {
		padding: 0;
	}

	p {
		margin: 0;
	}

	p.section-name {
		/* padding-left: 15px; */
	}

	p.condition-display,
	.condition-display {
		padding: 0 15px;
	}

	p.condition-display-alt {
		margin-left: -15px;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
	.mat-button-toggle-checked {
		background-color: $blue-color;
		color: $white-text;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
	.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
		line-height: 25px;
	}

	.custom-conditional-display {
		margin: 0 -15px;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
	.mat-button-toggle-group-appearance-standard {
		border-radius: 15px;
		border: 1px solid $blue-color;

		/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
		.mat-button-toggle:first-child {
			border-right: 1px solid $blue-color;
		}

		/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
		.mat-button-toggle:last-child {
			/* border-left: 1px solid $blue-color; */
		}
	}
}

.no-row {
	.custom-radio-button-set-wrapper {
		.row {
			margin: 0 5px;
		}
	}
}

.wizard-2 {
	.container.custom-radio-btn-wrapper {
		padding-left: 0;
	}
}

.health-history-wrapper {
	.custom-radio-btn-wrapper {
		padding-left: 5px !important;
		padding-right: 10px !important;
	}
}

.health-goals-wrapper,
.past-medical-history-wrapper {
	.text-input {
		height: 20px;
	}
}

.custom-radio-button-set-wrapper {
	.row {
		width: 100%;
	}

	p.section-question {
		margin-bottom: 0;
	}

	.radio-btn-set {
		margin: 20px 0;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
	.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
		line-height: 24px;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
	.mat-button-toggle-checked {
		background-color: $blue-color;
		color: $white-text;
	}
}

.referral-wrapper {
	button.button {
		margin-right: 20px;
	}
}

.header-profile-menu.mat-mdc-menu-panel {
	border-radius: 0px !important;
	margin-top: 10px !important;
	background-color: $header-color;

	.mat-mdc-menu-content {
		padding: 0;

		button.book-exam-link {
			background-color: $header-active-color;
			color: $global-text-color;

			&:hover {
				background-color: tint($header-active-color, 20%);
				color: $global-text-color;
			}
		}

		.mat-mdc-menu-item {
			line-height: 30px;
			height: 30px;
			min-height: unset;
			color: $header-text-color;
			text-align: left;

			span {
				font-size: 14px;
				color: inherit;

				&.badge {
					margin-left: 3px;
					display: inline-block;
					padding: 2px 5px;
					font-size: rem(12);
					font-weight: 600;
					line-height: 1;
					text-align: center;
					white-space: nowrap;
					vertical-align: text-bottom;
					border-radius: 4px;
				}
			}

			&:hover {
				color: $header-active-color;
			}
		}
	}
}

.address-checkbox {

	/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
	.mat-checkbox-inner-container {
		margin: inherit;
		top: 4px;
		margin-right: 8px;
	}
}

/* Referral slider override */
.referral-wrapper {
	.current-referrals {
		.mat-mdc-slider {
			position: initial !important;
		}

		.ngx-slider {
			.ngx-slider-bar {
				background: #caccd0;
				height: 2px;
			}

			.ngx-slider-bubble {
				font-size: rem(14);
				bottom: -24px;
			}

			.ngx-slider-selection {
				background: $blue-color;
			}

			.ngx-slider-pointer {
				background: $blue-color;
				width: 14px;
				height: 14px;
				top: -5px;
				outline: none;

				&::after {
					display: none;
				}
			}
		}

		.notes-field {
			.mat-mdc-form-field {
				display: unset;
			}
		}
	}
}

.request-referral-wrapper,
.request-new-time-slot-wrapper {
	.ngx-slider {
		.ngx-slider-bar {
			background: #caccd0;
			height: 2px;
		}

		.ngx-slider-bubble {
			font-size: rem(14);
			bottom: -24px;
		}

		.ngx-slider-selection {
			background: $blue-color;
		}

		.ngx-slider-pointer {
			background: $blue-color;
			width: 14px;
			height: 14px;
			top: -5px;
			outline: none;

			&::after {
				display: none;
			}
		}
	}

	.referral-checkbox {
		.error-message {
			margin-top: -10px !important;
			margin-left: 23px !important;
		}
	}
}

/* Accordion Override */
.referral-wrapper,
.wellness-wrapper,
.immunization-wrapper,
.wellness-wrapper,
.health-record-wrapper,
.health-coach-wrapper {
	.mat-expansion-panel:not([class*='mat-elevation-z']) {
		/* box-shadow: none; */
		/* border-bottom: 1px solid $cancel-button-color; */
		/* border-radius: 0; */
	}

	.mat-accordion .mat-expansion-panel:first-of-type,
	.mat-accordion .mat-expansion-panel:last-of-type {
		/* border-radius: 0; */
	}

	.mat-accordion {
		.mat-expansion-panel-header-title {
			color: $header-color;
			opacity: 0.6;
			font-size: rem(18);
			font-weight: 700;
		}

		.mat-expansion-indicator {
			&:after {
				color: $active-menu-color;
				opacity: 0.6;
			}
		}

		.mat-expansion-panel {
			&.mat-expanded {
				/* border-bottom: 2px solid $blue-color; */
			}
		}

		.mat-expansion-panel-header {
			&.mat-expanded {

				/* background-color: #F5F8FB; */
				/* background: rgba(161,202,240,1); */
				/* Permalink - use to edit and share this gradient: http:/*colorzilla.com/gradient-editor/#487aaf+0,ffffff+24&0.55+0,0+100 */
				/* background: -moz-linear-gradient(top, rgba(72,122,175,0.55) 0%, rgba(255,255,255,0.42) 24%, rgba(255,255,255,0) 100%); */
				/* background: -webkit-linear-gradient(top, rgba(72,122,175,0.55) 0%,rgba(255,255,255,0.42) 24%,rgba(255,255,255,0) 100%); */
				/* background: linear-gradient(to bottom, rgba(72,122,175,0.55) 0%,rgba(255,255,255,0.42) 24%,rgba(255,255,255,0) 100%); */
				/* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#8c487aaf', endColorstr='#00ffffff',GradientType=0 ); */
				.mat-expansion-panel-header-title {
					opacity: 1;
				}

				.mat-expansion-indicator {
					&:after {
						opacity: 1;
						color: $header-color;
					}
				}
			}
		}

		.mat-expansion-panel-spacing {
			/* margin: 0; */
		}

		.mat-expansion-panel-header.mat-expanded {
			/* border-top: 2px solid $blue-color; */
		}
	}

	.mat-sort-header-arrow {
		display: none;
	}

	.mat-sort-header-container {
		align-items: center !important;
		justify-content: center;
	}

	.mat-mdc-table {
		display: block;
		background-color: none !important;
	}

	.date {
		.mat-sort-header-container {
			.mat-sort-header-arrow {
				display: none;
			}
		}
	}
}

.referral-wrapper {
	.c-r-module {
		&:first-child {
			.mat-accordion {
				.mat-expansion-panel {
					border-radius: 4px 4px 0 0;

					&.mat-expanded {
						margin-top: 0;
					}
				}
			}
		}

		&:last-child {
			.mat-accordion {
				.mat-expansion-panel {
					border-radius: 0 0 4px 4px;

					&.mat-expanded {
						margin-bottom: 0;
					}
				}
			}
		}

		.mat-accordion {
			.mat-expansion-panel {
				border-radius: 0;

				&.mat-expanded {
					margin: 16px 0;
					border-radius: 4px;
				}
			}
		}
	}

	.mat-expansion-panel-content {
		flex-direction: inherit;
	}

	.mat-expansion-panel-body {
		/* padding: 0 16px 16px; */
	}
}

.inbox-wrapper {
	.message {

		/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
		.mat-checkbox-layout {
			margin-bottom: 3px;
		}
	}
}

.ngx-editor {
	.ngx-wrapper {
		.ngx-editor-textarea {
			border: 0px !important;
			min-height: 150px !important;
		}
	}

	.ngx-editor-grippie {
		background-color: transparent !important;
		border: 0px !important;
	}
}

/* To override the page scroll when mat-dialog is opened */
.cdk-global-scrollblock {
	position: static;
	overflow: hidden !important;
}

/* To override radio button text in confimation modal - health coach */
.health-coach-wrapper {
	.confirm-appt {

		/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
		.mat-radio-label {
			padding-top: 19%;
		}

		/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
		.mat-radio-label-content {
			padding-left: 2px;
			padding-right: 10px;
			margin-top: -6px;
		}

		/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
		.mat-radio-outer-circle,
		.mat-radio-inner-circle {
			height: 15px;
			width: 15px;
		}

		/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
		.mat-radio-ripple {
			left: calc(40% - 15px);
			top: calc(40% - 15px);
			height: 30px;
			width: 30px;
		}
	}
}

/* To override pagination style in messaging component */
.inbox-wrapper {
	.mat-mdc-paginator-container {
		justify-content: center !important;
	}

	.mat-mdc-paginator-range-actions {
		width: 140px;
		position: relative;
		height: 40px;
	}

	.mat-mdc-paginator-range-label {
		margin: 4px auto 0;
	}

	.mat-mdc-paginator-navigation-previous {
		position: absolute;
		left: 0;
	}

	.mat-mdc-paginator-navigation-next {
		position: absolute;
		right: 0;
	}

	.mat-mdc-paginator-page-size {
		display: none;
	}
}

/* Megamenu override */
.menu-trigger[aria-expanded='true'] {
	span.arrow {
		&:before {
			content: '\f106' !important;
		}
	}
}

.megamenu-bg {
	background-color: rgba(0, 0, 0, 0.4);
}

.mat-mdc-menu-panel.megamenu {
	max-width: none;
	width: 100vw;
	overflow: visible;
	border-radius: 0px;
	margin-top: 15px;
	background-color: $white-text;
	box-shadow: none;

	.mat-mdc-menu-content {
		padding: 30px 0;

		.col-md-2 {
			flex: 0 0 15%;
			max-width: 15%;
		}
	}

	.h-results {
		img {
			margin-bottom: 16px;
			width: 100%;
		}

		.h-results-content {
			h6 {
				color: $blue-color;
				margin-bottom: 4px;
				font-size: rem(18);
				text-transform: uppercase;
				font-weight: 600;
			}

			p {
				font-size: rem(16);
				color: $placeholder-text-color;
				margin-bottom: 0;

				a {
					color: $blue-color;
					border-bottom: 1px solid $blue-color;
					text-decoration: none;

					&:hover {
						color: tint($blue-color, 20);
						border-bottom: 1px solid tint($blue-color, 20);
					}
				}
			}
		}
	}

	.links {
		list-style: none;
		padding-left: 0;

		li {
			color: $blue-color;
			margin-bottom: 10px;
			font-size: rem(14);
			text-transform: uppercase;
			font-weight: 600;

			&.link-title {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				a {
					font-size: rem(16);
					text-transform: uppercase;
					font-weight: 600;
					text-decoration: none;
					color: $blue-color;
				}
			}

			a {
				font-weight: 400;
				text-transform: none;
				text-decoration: none;
				color: $placeholder-text-color;

				&.active,
				&:hover {
					color: $blue-color;
				}
			}
		}
	}
}

/* Dashboard Health Coaching SVG Override */
.d-h-m-svg-conatiner {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;

	svg {
		fill: $placeholder-text-color;
		width: 100%;
	}

	&.active-category-img {
		svg {
			fill: $blue-color;
		}
	}
}

.health-history-wrapper {
	#modal.active {
		z-index: 1020 !important;
		top: 50px;

		@media screen and (max-width: 991.98px) {
			top: 115px;
		}
	}

	#modal.active:after {
		top: 20px;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
	.mat-checkbox-frame {
		border-width: 1px;
		border-radius: 5px;
	}

	.health-history-slider.ngx-slider {
		span.ngx-slider-span.ngx-slider-pointer.ngx-slider-pointer-min {
			top: -40px;
			background-color: $blue-color;
			border-radius: 20px 20px 20px 0px;
			transform: rotate(-43deg);

			&:focus {
				outline: none;
			}
		}
	}
}

.health-history-table {
	.table-row {

		/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
		.mat-radio-label {
			margin-bottom: 0;
		}
	}
}

/* NgxEditor toolbar override with Font Awesome 5+ */
.compose-message-wrapper,
.reply-wrapper {
	.ngx-editor {
		.ngx-toolbar {
			background-color: transparent;
			border-top: none;
			border-left: none;
			border-right: none;
			border-bottom: 1px solid $aside-border-color;
		}
	}
}

.ngx-editor-button {
	.fa {
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
	}

	.fa-scissors {
		&:before {
			content: '\f0c4';
		}
	}

	.fa-files-o {
		&:before {
			content: '\f0ea';
		}
	}

	.fa-repeat {
		&:before {
			content: '\f01e';
		}
	}

	.fa-chain-broken {
		&:before {
			content: '\f127';
		}
	}
}

/* Toast alert customization */
.toast-info {
	background-color: $toast-info;
}

.toast-success {
	background-color: $toast-success;
}

.toast-error {
	background-color: $toast-error;
}

.toast-warning {
	background-color: $toast-warning;
}

.toast-container {
	@media screen and (max-width: 575.98px) {
		right: 0 !important;
		width: 100%;
		padding: 0 4%;

		.toast {
			width: 100%;
		}
	}
}

.toast-container .toast {
	box-shadow: none !important;
	opacity: 1 !important;

	&:hover {
		box-shadow: none !important;
	}
}

.toast-top-right {
	top: 100px;
	right: 20px;
}

button.toast-close-button {
	position: absolute;
	right: 10px !important;
	top: 0px !important;
}

/* Message body override */
.message-body {
	p {
		line-height: 26px;

		a {
			color: $blue-color !important;

			&:hover {
				color: tint($blue-color, 30);
			}
		}
	}
}

.spinner {
	.material-loader-wrapper {
		.black-overlay {
			position: fixed !important;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
		}
	}
}

.spinner-hide {
	.material-loader-wrapper {
		.black-overlay {
			background-color: rgba(51, 51, 51, 1) !important;
		}
	}
}

.screening-wrapper {
	.pad-0 {
		padding: 0 !important;
	}
}

.health-history-wrapper {
	.mat-mdc-form-field-error {
		/* display: none !important; */
	}

	.ha-info-icon {
		color: #ccc;
	}

	.mat-expansion-indicator::after,
	.mat-expansion-panel-header-description {
		color: $blue-color;
	}

	button.button {
		padding: 0px 40px;
		border-radius: 40px;
		height: 35px;
		line-height: 33px;
	}

	.base-bar button.button {
		padding: 0 50px;
		font-size: 0.8rem;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
	.mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
		border-left: none !important;
	}

	.ha-not-sure {
		border-left: 1px solid $blue-color !important;
	}

	.q-q {
		padding: 15px;
		padding-left: 0;
		width: 31px;
	}

	.d-cent {
		background: #f5f5f5;
		padding: 10px;
		min-width: 200px;
		max-width: 200px;
		flex-basis: auto;
		flex-grow: 1;
	}

	.d-cent-2 {
		background: #e0e0e0;
		padding: 10px;
		width: auto;
		flex-grow: 1;
	}

	.d-r {
		border-bottom: 1px solid $blue-color;

		@media screen and (max-width: 991.98px) {
			overflow-x: auto;
		}
	}

	.d-tt {
		border-top: 1px solid $blue-color;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
	.mat-checkbox-inner-container {
		width: 20px;
		height: 20px;
	}

	.ngx-slider .ngx-slider-active:after {
		background-color: $white-text !important;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
	.mat-slider-horizontal {
		width: 100%;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
	.mat-slider-horizontal .mat-slider-ticks {
		background-image: repeating-linear-gradient(to right, $blue-color, $blue-color 2px, transparent 0, transparent);
	}

	/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
	.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
	.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
		background-color: $blue-color;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
	.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
		border-color: $blue-color;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
	.mat-button-toggle-group-appearance-standard,
	.mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
		border-radius: 15px;
		border-color: $blue-color;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
	.mat-button-toggle-appearance-standard {
		border-right: 1px solid $blue-color;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
	.mat-button-toggle-appearance-standard:last-child {
		border-right: none !important;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
	.mat-checkbox-checked.mat-accent .mdc-checkbox__background {
		border-radius: 5px;
	}

	.bodymap {
		width: 100%;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
	.mat-slider-horizontal .mat-slider-wrapper {
		left: 0;
		right: 0;
	}

	.ha-sm-button {
		height: 26px !important;
		line-height: 22px !important;
		padding: 0px 50px !important;
	}

	.ha-black-stroke {
		border-color: #000;
		border-color: rgba(0, 0, 0, 0.43);
	}

	.ha-ac {

		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		span.mat-form-field-label-wrapper::after {
			width: 0;
			height: 0;
			border-left: 5px solid transparent;
			border-right: 5px solid transparent;
			border-top: 5px solid;
			margin: 0 4px;
			content: '';
			position: absolute;
			right: 0;
			top: 50%;
			color: rgba(0, 0, 0, 0.54);
		}
	}

	/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
	.mdc-checkbox__background {
		border-radius: 7px !important;
	}

	input[readonly] {
		pointer-events: none;
		cursor: default;
		color: rgba(0, 0, 0, 0.38);
	}

	.disable-override {
		pointer-events: none;
		color: #000000 !important;
	}
}

.section-question.pad-fix {
	margin-bottom: 9.2px !important;
	margin-top: -5px !important;
}

.pad-10 {
	padding-bottom: 10px;
}

.ha-tool-d {
	top: 6px;
	position: relative;
	padding-left: 5px;
}

.ha-tool-15 {
	top: 15px !important;
}

.m-s {
	font-size: 15px;
	padding-bottom: 15px;
	font-weight: 600;
}

#bodyMapGroup {
	.d-flex.pad-10 {
		padding: 10px;
	}
}

.legend {
	.left {
		float: left;
	}

	.right {
		float: right;
	}
}

/* Adaptive Card override */
.ac-image {
	img {
		padding-top: 10px;
	}
}

.ac-anchor {
	color: $blue-color;

	&:hover {
		color: tint($blue-color, 40%);
	}
}

.d-filter.hide-sort {
	padding: 0 0 41px !important;

	/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
	mat-select {
		display: none;
	}
}

.d-feed-content {
	.ac-container {
		padding: 0 !important;

		.seperator {
			margin-top: 6.5px !important;
		}

		i {
			&:hover {
				cursor: pointer;
			}
		}

		.cardDate {
			display: none !important;
		}

		p {
			z-index: 10;
		}
	}

	.verify-account-error {
		background: $event-banner-color;
		padding: 5px 20px !important;

		p {
			color: $event-banner-text-color;
		}
	}
}

.ac-selectable {
	p {
		color: $blue-color;

		&:hover {
			color: tint($blue-color, 40%);
			cursor: pointer;
		}
	}
}

/* Overriding style for referral requests */
.referral-wrapper {
	.my-referral-requests {
		.c-r-module {
			.mat-expansion-panel-body {
				width: 100%;
			}

			.c-r-content {
				.c-r-row {}
			}
		}
	}
}

.review-appointment-wrapper {

	/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
	.mat-button-toggle-group-appearance-standard,
	.mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
		border-radius: 15px;
		border-color: $blue-color;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
	.mat-button-toggle-appearance-standard {
		border-right: 1px solid $blue-color;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
	.mat-button-toggle-appearance-standard:last-child {
		border-right: none !important;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
	.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
		line-height: 28px;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
	.mat-button-toggle-checked {
		background-color: $blue-color;
		color: $white-text;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.mat-form-field-appearance-legacy .mat-form-field-underline {
		background-image: none !important;
		background-color: rgba(0, 0, 0, 0.42) !important;
	}

	.cellphone-form-field {

		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-flex {
			border-bottom: 1px solid $border-color;

			.mat-mdc-input-element {
				text-align: right;
			}

			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			.mat-form-field-label {
				color: black;
				font-size: rem(20);
				padding-top: 10%;
			}

			.mat-form-field-empty {
				padding-top: 5px;
				font-size: rem(16);
			}
		}

		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-mdc-form-field.mat-focused .mat-form-field-label {
			font-size: rem(20) !important;
			padding-top: 10% !important;
		}
	}

	.privacy-check {

		/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
		.mat-checkbox-inner-container {
			margin-top: 5px !important;
		}
	}

	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.mat-form-field-appearance-outline .mat-form-field-infix {
		padding: 0;
	}

	.mammo-date-field {

		/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
		.mat-radio-container {
			display: none;
		}

		.mat-datepicker-toggle {
			display: none;
		}

		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-label {
			color: $default-text-color;
		}
	}

	.error {

		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-label {
			color: $error-message-text-color;
		}

		i.far {
			color: $error-message-text-color !important;
		}
	}

	.mammo-check-box {

		/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
		.mat-radio-outer-circle {
			border-width: 1px !important;
		}
	}
}

.simplified-wrapper {
	.appointment-details-wrapper {
		.appointment-info {
			width: 100% !important;

			.g-content {
				padding: 15px !important;
			}
		}
	}
}

/* ngx editor popup fix */
.ngxe-popover {
	min-width: 100% !important;
}

/* Immunization sort override */
.mat-sort-header-container {
	.mat-sort-header-arrow.ng-trigger.ng-trigger-arrowPosition {
		opacity: 0.5 !important;
		transform: none !important;
	}

	&.mat-sort-header-sorted {
		.mat-sort-header-arrow.ng-trigger.ng-trigger-arrowPosition {
			opacity: 1 !important;
			transform: none !important;
		}
	}
}

/* To override messaging style in IE */
.message-wrapper.ie,
.compose-wrapper.ie,
.inbox-wrapper.ie {
	.ngx-editor {
		.ngx-wrapper {
			.ngx-editor-placeholder {
				display: none !important;
			}
		}
	}

	.mat-mdc-paginator-range-label {
		margin-top: 40px;
	}
}

/* Adaptive Card override */
.ac-image {
	img {
		padding-top: 10px;
	}
}

.ac-anchor {
	color: $primary-font-color;

	&:hover {
		color: tint($primary-font-color, 40%);
	}
}

.d-feed-content {
	.ac-container {
		padding: 0 !important;

		.seperator {
			margin-top: 6.5px !important;
		}

		i {
			&:hover {
				cursor: pointer;
			}
		}

		.ac-image {
			img {
				width: 100%;
				max-height: 260px;
			}
		}
	}

	.verify-account-error {
		background: $event-banner-color;
		padding: 5px 20px !important;

		p {
			color: $event-banner-text-color;
		}
	}
}

.ac-selectable {
	p {
		color: $primary-font-color;

		&:hover {
			color: tint($primary-font-color, 40%);
			cursor: pointer;
		}
	}
}

/* Hiding header, bookmark and close buttons in feed */
.ac-container {

	.fa,
	.ac-columnSet,
	.separator {
		display: none;
	}

	.title {
		p {
			text-transform: capitalize;
		}
	}
}

/* Find appointment Wrapper */
.find-appointment-wrapper {
	.inline-form {

		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-subscript-wrapper {
			position: relative;
		}
	}

	.date {

		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-underline {
			display: none;
		}
	}

	.date-error {

		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-wrapper {

			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			.mat-form-field-flex {
				border: 1px solid $error-message-text-color !important;
			}
		}

		.mat-mdc-form-field-error {
			font-size: 0.85rem;
		}
	}

	.close {
		color: $secondary-font-color !important;
	}
}

/* Added width for modal to work in ie */
.mat-modal-wrapper {
	.mat-mdc-dialog-container {
		width: 500px;
	}
}

.text-on-img-container {
	position: relative;

	.float--over-image {
		position: relative;

		p {
			top: 30%;
			position: absolute;
			padding: 0 0 0 20px;
			color: #fff;
			font-size: rem(38);
			line-height: rem(38);
			width: 240px !important;
		}
	}

	.ac-columnSet {
		display: none !important;
	}

	.separator {
		display: none;
	}
}

/* Mat Dialog Height */
.mat-mdc-dialog-content {
	max-height: 45vh !important;
}

.sch-locations {
	display: flex;
	padding-bottom: 15px;

	.mat-mdc-checkbox {
		padding-right: 15px;
		margin-top: 3px;
	}
}

/* Select3-Component arrow fix in select dropdown */
.select-3-wrapper {

	/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
	.mat-select-arrow-wrapper {

		/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
		.mat-select-arrow {
			border: 0 !important;
		}
	}
}

.major-menu {
	.mdc-form-field {
		width: 100%;
		margin-bottom: 0;

		.mdc-checkbox:first-child {
			display: none;
			margin-left: 0;
		}
	}
}

.sub-menu-li {
	.major-menu {
		.mdc-form-field {
			width: 100%;

			.mdc-checkbox:first-child {
				display: inline-block;
				margin-left: 0;
			}
		}
	}
}

/* Dashboard new style guide */
.d-s-module {
	&.welcome-panel {
		padding: 0 0 20px 0 !important;

		.d-welcome {
			h2 {
				background: $primary-font-color;
				color: $white-background-color !important;
				margin-bottom: 22px !important;
				padding: 10px 20px;
				border-radius: 4px 4px 0 0;
			}

			.d-w-action,
			.d-carousel {
				margin: 0 20px;
			}
		}
	}
}

.mat-accordion {
	.mat-expansion-panel {
		border-bottom-right-radius: 4px !important;
		border-bottom-left-radius: 4px !important;
	}
}

.procedure-select-box-wrapper {
	.major-menu {
		.mat-mdc-checkbox {
			.mdc-form-field {
				white-space: nowrap !important;
			}
		}
	}
}

.incentive-modal-wrapper {
	.u-verification-modal-wrapper {
		border-radius: 2px !important;
	}
}

/* Tooltip for Datepicker */
.app-tooltip::after,
.app-tooltip::before {
	display: none;
	position: absolute;
	top: 0;
	left: 50%;
	color: rgba(0, 0, 0, 0.87);
	opacity: 0;
}

.app-tooltip-enable::after,
.app-tooltip-enable::before {
	display: block;
	opacity: 1;
}

.app-tooltip::before {
	content: '';
	width: 10px;
	height: 10px;
	background: #fff;
	transform: rotate(45deg);
	margin-left: -5px;
	box-shadow: -2px -2px 5px -2px rgba(0, 0, 0, 0.4);
	margin-top: 20px;
	z-index: 9999;
}

.app-tooltip::after {
	content: attr(tooltip-content);
	text-transform: none !important;
	transform: translateY(10px);
	font-size: rem(14);
	font-weight: 400;
	margin-left: -20px;
	border-radius: 1px;
	padding: 10px 5px 10px 5px;
	text-align: left;
	z-index: 1;
	box-shadow:
		0 2px 4px -1px rgba(0, 0, 0, 0.4),
		0 4px 5px 0 rgba(0, 0, 0, 0.3),
		0 1px 5px 0 rgba(0, 0, 0, 0.12);
	background-color: #fff;
	width: 250px;
	overflow-y: auto;
	white-space: pre-wrap;
	margin-top: 15px;
	line-height: rem(16px);

	@media (max-width: 575.98px) {
		left: 0 !important;
		max-width: 80vw !important;
	}
}

.app-tooltip:hover::before,
.app-tooltip:hover::after {
	opacity: 1;
	display: block;
}

.car-service-wrapper {
	.u-verification-modal-wrapper {
		border-radius: 2px !important;
		box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3) !important;
	}

	.checkbox {

		/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
		.mat-checkbox-frame {
			border-width: 1px !important;
			border-color: $primary-font-color !important;
			padding: 9px;
		}

		/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
		.mdc-checkbox__background {
			padding: 10px;
		}

		/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
		.mat-checkbox-label {
			padding-top: 3px;
			padding-left: 5px;
		}
	}
}

/* Removing yellow hover on buttons */
.scheduling-wrapper {
	.mat-mdc-outlined-button.button {

		&:hover,
		&:active {
			background-color: $white-background-color !important;
		}
	}
}

.wordpress-modal-wrapper {
	.overlay-wrapper {
		padding: 0 !important;
		border-radius: 0 !important;

		.heading {
			display: none !important;
		}
	}

	.buttons {
		padding-bottom: 0 !important;
	}

	.modal-body {
		padding: 0 !important;
		margin-top: -30px;
	}

	.close {
		top: 30px;
		right: 30px;
	}

	.close:before,
	.close:after {
		height: rem(46);
		width: 1px;
	}
}

.wp-overlay {
	overflow: auto !important;
	-webkit-overflow-scrolling: touch !important;

	.mat-mdc-dialog-container {
		border-radius: 0 !important;
	}
}

.login-wrapper {
	padding-top: 0 !important;
}

.mat-mdc-tab-header,
.mat-mdc-tab-nav-bar {
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

app-login-simplified .content-box.form-wrapper {
	.mat-mdc-tab-labels {
		padding-top: 15px;
	}

	.mat-mdc-tab-body {
		padding: 25px 35px;
		min-height: 431px;

		@media screen and (max-width: 575.98px) {
			padding: 25px 3%;
		}
	}

	.mat-mdc-tab {
		width: 50%;
		font-size: 14px;

		&.mdc-tab--active .mdc-tab__text-label {
			font-weight: bold;
		}

		.mdc-tab__text-label {
			font-size: 18px;
			color: #000;
			opacity: 1;
		}

		.mdc-tab-indicator__content--underline {
			border-top-width: 5px;
			border-color: $active-tab-color;
		}
	}

	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.mat-form-field-infix {
		border-top: 0;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.dob-wrapper .mat-form-field-appearance-legacy.mat-form-field-should-float .mat-form-field-label {
		top: 1.85em;
	}

	.mat-grid-list {
		@media screen and (max-width: 320px) {
			padding-bottom: calc(4 * ((25% + 5vw) * 1) + 0px + 3px) !important;
		}
	}

	.mat-grid-tile {
		@media screen and (max-width: 320px) {
			padding-top: calc((25% + 5vw) * 1 + 0px) !important;
		}

		.mat-grid-tile-content {
			margin-top: 12px;
		}
	}
}

.password-show-hide {
	position: absolute;
	font-size: rem(14);
	line-height: rem(20);
	right: calc(100% - 100%);
}

.cancel-appointment-wrapper {
	.reason-textbox {

		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-appearance-outline .mat-form-field-infix {
			padding: 0 0 10px 0 !important;
		}
	}
}

.migration-wrapper,
.activate-account-wrapper {
	.password-wrapper {
		.strength-wrapper {
			.mat-mdc-form-field-hint {
				left: calc(100% - 33%);
			}
		}
	}
}

.activate-account-wrapper {

	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.mat-form-field-infix {
		margin-top: 20px !important;
	}
}

/* Overriding password strength indicator */
.password-wrapper {
	.hide {
		display: none !important;
	}

	.strength-wrapper {
		.mat-mdc-form-field-hint {
			position: absolute;
			left: calc(100% - 15%);
			font-size: rem(12) !important;
		}
	}

	.mat-mdc-form-field {
		margin-bottom: 0 !important;
	}

	.mat-mdc-card {
		box-shadow: none;

		@media screen and (max-width: 991px) {
			font-size: 14px;
		}

		p {
			font-size: rem(16);
			text-align: left;
		}
	}

	.mat-icon.mat-primary {
		color: $green-check;
	}

	.mat-icon.mat-warn {
		color: $red-exclamation;
	}

	.mat-mdc-progress-bar {
		margin-top: 5px;
		margin-bottom: 5px;
		width: 85%;
		--mdc-linear-progress-active-indicator-height: 7px;
		--mdc-linear-progress-track-height: 7px;
		--mdc-linear-progress-active-indicator-color: #{$green-check};
		--mdc-linear-progress-track-color: fade-in(#{$green-check}, 0.8);

		&.mat-warn {
			--mdc-linear-progress-active-indicator-color: #{$red-exclamation};
			--mdc-linear-progress-track-color: fade-in(#{$red-exclamation}, 0.8);
		}

		&.mat-accent {
			--mdc-linear-progress-active-indicator-color: #{$warning-color};
			--mdc-linear-progress-track-color: fade-in(#{$warning-color}, 0.8);
		}
	}
}

/* Appointment version - 2 */
.find-appointment-wrapper {
	.inventory-message {
		margin-top: 5px;
		text-align: center;
		font-weight: 600;

		mark {
			background-color: yellow;
		}
	}

	/* overriding calendar styles */
	.calendar {
		.mat-calendar {
			height: auto !important;

			.mat-calendar-header {
				.mat-calendar-controls {
					display: flex;
					flex-direction: column;
					padding: 15px;
					margin: 0;
				}

				.mat-calendar-previous-button {
					position: absolute;
					left: 0;
					margin-left: 75px;
				}

				.mat-calendar-next-button {
					position: absolute;
					right: 0;
					margin-right: 75px;
				}

				.mat-calendar-next-button::after,
				.mat-calendar-previous-button::after {
					margin: 10px;
				}

				.mat-calendar-period-button {
					text-align: center;
					font-size: rem(32);
					line-height: 40px;
					font-weight: 400;

					.mdc-button__label {
						text-transform: lowercase;
						display: inline-block;
					}

					.mdc-button__label::first-letter {
						text-transform: uppercase;
					}
				}
			}

			.mat-calendar-content {
				margin-top: 20px;

				th,
				td {
					font-size: rem(16);
					line-height: 24px;
				}

				.mat-calendar-table-header-divider {
					display: none;
				}
			}
		}
	}

	.location {
		.mat-mdc-form-field {
			width: 100%;
		}

		.location-drop-down {
			.mat-mdc-form-field-wrapper {
				padding-bottom: 0;
			}

			.mat-mdc-select {
				display: none;
				visibility: hidden;
			}

			.mdc-line-ripple {
				display: none;
			}

			.mat-mdc-form-field-label {
				font-size: rem(18);
				line-height: 20px;
			}

			.mat-mdc-form-field-infix {
				min-height: unset;
				width: 100%;
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}

	/* Styles for mobile */
	.mob-slot-section {
		.slots-section {
			.slots-box {
				margin-left: 0 !important;

				.slot-row {
					padding: 0 10px 0 10px;
					margin-right: 0 !important;

					.stroked {
						.slot-rectangle {
							height: 45px;
							width: 100%;
							border-radius: 25px;
						}

						.slot-available {
							border: 2px solid #2e58af;
						}

						.display-time {
							display: flex;
							justify-content: center;

							.slot-time {
								margin-right: 5px;
								font-size: rem(18);
							}

							.slot-am-pm {
								font-size: rem(18);
								padding-top: 0;
								margin-top: 0;
								height: 15px;
								line-height: 24px;
							}
						}
					}
				}
			}
		}
	}

	.mob-calendar {
		.mat-calendar {
			height: auto !important;
		}

		.mat-calendar-content {

			th,
			td {
				font-size: rem(16);
				line-height: 24px;
			}
		}

		.mat-calendar-period-button {
			font-size: rem(16);
		}
	}

	.mob-procedure-select {
		.selected-procedure-v2 {
			color: $primary-font-color !important;
		}

		.select-box-v2 {
			i {
				color: $primary-font-color !important;
			}
		}
	}
}

.incentives-wrapper {
	.skip-incentive-check-box {

		/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
		.mat-checkbox-frame {
			border-width: 1px;
			border-color: $primary-font-color;
		}

		/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
		.mat-checkbox-inner-container {
			height: 20px;
			width: 20px;
		}
	}

	.shipping-email-field {
		.email-hint {
			padding: 10px 0 10px 0;
			font-size: rem(14);
			line-height: rem(18);
			color: black;
			margin-top: -20px;
		}
	}

	a {
		color: $primary-font-color !important;
	}
}

.content-box {
	.home-page-wrapper {
		width: 100%;
	}
}

.overlay-no-padding {
	overflow: auto !important;

	.mat-mdc-dialog-container {
		padding: 0px;
	}
}

.member-info-confirmation-modal-wrapper {
	.overlay-wrapper {
		padding: 0 !important;
	}

	@media screen and (max-width: 767.98px) {
		.modal-body {
			padding: 0 !important;
		}
	}
}

.cp-text {
	display: inline-block;

	.app-tooltip::before {
		width: 25px;
		height: 25px;
		margin-top: 25px;
	}

	.app-tooltip::after {
		width: 400px;
		padding: 15px;
		font-weight: 500;
		margin-top: 25px;
		line-height: rem(18px);
	}

	.app-tooltip:hover::before,
	.app-tooltip:hover::after {
		opacity: 0;
		left: 80%;
	}

	.app-tooltip:hover::after {
		margin-left: 0px;
	}

	@media screen and (max-width: 767.98px) {

		.app-tooltip::after,
		.app-tooltip::before {
			left: 50%;
		}
	}
}

.cp-text:hover {

	.app-tooltip::before,
	.app-tooltip::after {
		opacity: 1;
		left: 20%;
		top: 80%;
	}

	.app-tooltip::after {
		margin-left: -200px;
	}
}

.cdk-overlay-pane.video-overlay {
	position: relative !important;
}

.new-ha-wrapper {
	overflow: hidden;

	.section-info-hide {
		div {
			display: none;
		}
	}

	.mat-step {
		background-color: $ha-bg-color;
	}

	.mat-step-header.cdk-keyboard-focused,
	.mat-step-header.cdk-program-focused,
	.mat-step-header:hover {
		background-color: transparent !important;
	}

	.section-wrapper {
		background-color: white;
		margin-top: 15px;
		box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
		padding: 10px 92px 55px 40px;
	}

	.mat-mdc-form-field {
		/* .mat-form-field-flex{ */
		/*   height: 70px; */
		/* } */
		width: 100%;

		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-label-wrapper {
			left: 10px;

			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			.mat-form-field-label {
				color: $placeholder-text-color-gray;
				font-size: rem(18);
				letter-spacing: -0.25px;
				line-height: rem(24);
			}
		}

		/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
		.mat-select-arrow {
			width: 13px;
			height: 8px;
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
			border-top: 7px solid;
		}

		/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
		.mat-select-arrow-wrapper {
			display: inline;
		}
	}

	.text-area-wrapper {
		.mat-mdc-form-field {

			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			.mat-form-field-flex {
				height: auto !important;
			}
		}
	}

	.ha-health-goals-section {
		.mat-mdc-form-field {
			margin-bottom: -10px !important;
		}
	}

	.ha-family-history-section {
		.mat-mdc-form-field {

			/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
			.mat-form-field-flex {
				height: 70px;
				border-radius: 4px 4px 0 0;
				background-color: $ha-form-bg-color;
			}

			/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
			.mat-select-value {
				padding: 10px 20px;
			}
		}

		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-label {
			&.mat-form-field-empty {
				margin: 0 10px;
			}
		}

		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-form-field-label {
			margin: 10px;
		}

		/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
		.mat-select-arrow {
			margin: 5px 20px;
		}
	}

	.padding-right {
		.mdc-label {
			padding-right: 0 !important;
		}
	}

	.mat-mdc-radio-button {
		padding-top: 10px;

		.mdc-form-field .mdc-label {
			padding-right: 80px;
			color: $default-text-color;
			font-size: rem(18);
			letter-spacing: -0.25px;
			line-height: rem(24);
		}
	}

	.heading {
		margin-top: 20px;
		color: $green-one;
		font-size: rem(16);
		font-weight: bold;
		letter-spacing: 0;
		line-height: rem(24);
		text-transform: uppercase;
		padding-bottom: 5px;
	}

	.finish {
		.mat-mdc-button.button {
			width: 152px !important;
			text-transform: none !important;
		}
	}

	.save-btn {
		margin: 30px 0 20px 0;

		.mat-mdc-button.button {
			height: 35px;
			width: 133px;
			font-size: rem(16);
			letter-spacing: 0;
			line-height: rem(19);
			text-align: center;
			justify-content: center;
			padding: 0;
			text-transform: capitalize;
		}
	}

	.radio-btn-label {
		margin: 10px 0 10px 0;
		color: $default-text-color;
		font-size: rem(18);
		letter-spacing: -0.25px;
		line-height: rem(24);
	}

	.text-area {
		height: 22px;
		white-space: pre-wrap;
	}

	.checkbox-text {
		margin: 5px 0 20px 0;
	}

	.checkbox-list {

		/* margin: 10px 0 10px 0; */
		.mat-mdc-checkbox {

			/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
			.mat-checkbox-label {
				color: $default-text-color;
				font-size: rem(16);
				letter-spacing: -0.38px;
				line-height: rem(24);
			}

			/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
			.mat-checkbox-inner-container {
				border-radius: 2.5px;
				height: 22px;
				width: 22px;
			}

			/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
			.mat-checkbox-frame {
				border-width: 1px;
			}
		}
	}

	.read-only {
		.mat-mdc-button.button {
			cursor: default;
		}

		li {
			a {
				color: grey !important;
			}
		}
	}

	.new-user {
		li {
			cursor: default;
			pointer-events: none;
		}
	}

	ul {
		li {
			a {
				color: $blue-color;

				&:hover {
					font-weight: bold;
				}
			}

			&.active {
				a {
					color: $default-text-color !important;
					font-weight: bold;
				}
			}
		}
	}

	.inline-input-field {
		display: inline-block;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
	.mat-slider-horizontal {
		width: 95%;
		margin-top: 15px;
	}

	.slider-show-value {

		/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
		.mat-slider-horizontal {
			margin: 0;
			margin-left: 25px;
			margin-top: 0;
		}
	}

	.slider-show-value-range {

		/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
		.mat-slider-horizontal {
			margin: 0;
			margin-left: 35px;
			margin-top: 0;
		}
	}

	.slider-show-value-text {
		display: flex;

		.slider-labels {
			padding-right: 10px;
			width: 90px;
			max-width: 90px;
		}
	}

	.ha-how-you-move-section {
		.slider {
			margin-top: 0 !important;
		}
	}

	.slider {
		margin-top: 24px;
		position: relative;
		min-height: 60px;
	}

	.h-70 {
		height: 70px;
	}

	.h-85 {
		height: 85px;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
	.mat-slider-horizontal .mat-slider-wrapper {
		left: 0px;
		right: 0px;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
	.mat-slider-wrapper {

		/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
		.mat-slider-track-wrapper,
		.mat-slider-track-background,
		.mat-slider-track-fill {
			height: 4px;
			border-radius: 2px;
		}

		/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
		.mat-slider-thumb {
			bottom: -11px;
		}

		/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
		.mat-slider-ticks-container {
			height: 4px;

			/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
			.mat-slider-ticks {
				height: 4px;
			}
		}
	}

	.new-user {
		.mat-vertical-stepper-header {
			pointer-events: none !important;
		}
	}

	.mat-mdc-slider.cdk-keyboard-focused .mat-mdc-slider-focus-ring,
	.mat-slider.cdk-program-focused .mat-slider-focus-ring {
		background-color: transparent;
	}

	.slider-labels {
		padding-top: 15px;

		.label-left,
		.label-right {
			font-size: rem(16);
			letter-spacing: -0.38px;
			line-height: rem(18);
			color: $default-text-color;
		}

		.label-left,
		.label-right {
			position: absolute;
			top: 0;
		}

		.label-right {
			right: 5%;
		}
	}

	.label-value {
		position: absolute;
		width: 20px;
		margin-top: 12px;
		color: $default-text-color;
	}

	.label-value-text {
		text-align: left;
		color: $default-text-color;
	}

	/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
	.mat-slider-ticks {
		opacity: 1;
	}

	.question-wrapper {
		margin: 25px 0 25px 0;

		.mat-mdc-radio-group {
			display: flex;
			flex-direction: column;
		}
	}

	.slider-group {
		.question-label {
			margin: 20px 0 20px 0;
			text-align: center;
		}
	}

	.ha-how-you-sleep-section {
		.slider-show-value-text .slider-labels {
			width: 30px;
		}

		.mat-mdc-slider {
			width: calc(95% - 25px);
		}
	}

	.ha-work-life-section {
		.label-value-text {
			width: 75px !important;
		}
	}

	.ha-work-life-section,
	.ha-substance-abuse-section {
		.slider-group-with-label {
			.mat-mdc-slider {
				width: calc(95% - 105px);
			}
		}
	}

	.error-message {
		top: calc(100% + 3px);
		left: -12px;
	}

	&.ie {
		.ha-family-history-section {
			.mat-select-arrow {
				margin: -5px 20px !important;
			}
		}
	}

	/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
	.mat-slider-min-value {

		/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
		.mat-slider-track-fill {
			background-color: rgba(0, 0, 0, 0.26) !important;
		}

		/* TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version. */
		.mat-slider-track-wrapper {
			margin-left: -2px !important;
		}
	}

	.text-area {
		overflow: hidden !important;
	}

	.ha-personal-info-section {
		.error-message {
			top: calc(100% + -4px);
		}
	}
}

pdf-viewer {
	display: block;
	height: 100%;
}

/** Small scren and Extra small screens * */
@media only screen and (max-width: 575.98px) {

	button,
	.button {
		max-width: 100% !important;
		white-space: normal !important;
	}
}

/** Small scren and Extra small screens * */
@media only screen and (max-width: 319.98px) {

	button,
	.button {
		height: fit-content !important;
	}
}

/** mobiles * */
@media only screen and (max-width: 767px) {
	.new-ha-wrapper {
		min-height: 90vh;

		.ha-head-content {
			width: 100% !important;
		}

		.ha-section {
			width: 100% !important;
			margin-top: 30px !important;
			margin-bottom: calc(6vw + 6vh - 1vmin);
		}

		.section-info {
			width: 100% !important;
		}

		.section-wrapper {
			padding: 10px 20px 10px 10px !important;
			margin-bottom: 10px;
		}

		.heading {
			margin-top: 0 !important;
		}

		.mat-step-label {
			width: 100% !important;
			font-size: rem(26) !important;
			line-height: rem(30) !important;
		}

		.mat-vertical-content {
			padding: 0 15px 0 15px !important;
		}

		.mat-vertical-stepper-header .mat-step-icon {
			margin-right: 0 !important;
		}

		.mat-step:last-of-type {
			.save-btn {
				min-height: 15vh;
			}
		}

		.ha-how-you-think-section {
			.slider-labels {

				.label-left,
				.label-right {
					font-size: rem(12);
				}
			}
		}
	}

	.ha-multi-select-box {
		min-width: 45% !important;
	}
}

/* iPad Portrait */
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.new-ha-wrapper {
		.ha-section {
			width: 80% !important;
		}

		.section-info {
			width: 85% !important;
		}

		.section-wrapper {
			padding: 10px 40px 20px 30px !important;
		}
	}
}

/* iPad landscape and iPad Pro portrait */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.new-ha-wrapper {
		.ha-section {
			width: 80% !important;
		}

		.section-info {
			width: 85% !important;
		}

		.section-wrapper {
			padding: 10px 40px 20px 30px !important;
		}
	}
}

.ha-multi-select-box {
	left: 40px;
	min-width: 100% !important;

	.mat-mdc-option.mat-mdc-selected {
		color: $new-blue-button !important;
	}

	.mat-pseudo-checkbox-checked {
		background: $new-blue-button !important;
	}
}

@media screen and (min-width: 414px) and (max-width: 736px) {
	.new-ha-wrapper {
		.ha-section {
			width: 95% !important;
		}
	}
}

/* iPhone6/7/8 Landscape */
@media (min-device-width: 375px) and (max-device-width: 736px) and (orientation: landscape) {
	.new-ha-wrapper {
		min-height: 100vh;
	}
}

/* iPhone6/7/8 Plus Landscape */
@media (min-device-width: 414px) and (max-device-width: 736px) and (orientation: landscape) {
	.new-ha-wrapper {
		.mat-vertical-content {
			padding: 0 15px 15px 15px !important;
		}
	}
}

/* Large screens */
@media (min-device-width: 1600px) and (max-device-width: 1920px) {
	// update if any
}

app-responsive-menu .sidebar-menu-on-small-devices {
	.mat-ink-bar {
		background-color: $header-color !important;
	}
}

.lock-scroll {
	overflow: hidden;
}