@use 'sass:math';

@font-face {
	font-family: 'Proxima Nova';
	src:
		url('/assets/fonts/ProximaNova-Bold.woff2') format('woff2'),
		url('/assets/fonts/ProximaNova-Bold.woff') format('woff'),
		url('/assets/fonts/ProximaNova-Bold.ttf') format('truetype'),
		url('/assets/fonts/ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src:
		url('/assets/fonts/ProximaNova-Light.woff2') format('woff2'),
		url('/assets/fonts/ProximaNova-Light.woff') format('woff'),
		url('/assets/fonts/ProximaNova-Light.ttf') format('truetype'),
		url('/assets/fonts/ProximaNova-Light.svg#ProximaNova-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src:
		url('/assets/fonts/ProximaNova-Medium.woff2') format('woff2'),
		url('/assets/fonts/ProximaNova-Medium.woff') format('woff'),
		url('/assets/fonts/ProximaNova-Medium.ttf') format('truetype'),
		url('/assets/fonts/ProximaNova-Medium.svg#ProximaNova-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src:
		url('/assets/fonts/ProximaNova-LightIt.woff2') format('woff2'),
		url('/assets/fonts/ProximaNova-LightIt.woff') format('woff'),
		url('/assets/fonts/ProximaNova-LightIt.ttf') format('truetype'),
		url('/assets/fonts/ProximaNova-LightIt.svg#ProximaNova-LightIt') format('svg');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova';
	src:
		url('/assets/fonts/ProximaNova-MediumIt.woff2') format('woff2'),
		url('/assets/fonts/ProximaNova-MediumIt.woff') format('woff'),
		url('/assets/fonts/ProximaNova-MediumIt.ttf') format('truetype'),
		url('/assets/fonts/ProximaNova-MediumIt.svg#ProximaNova-MediumIt') format('svg');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova';
	src:
		url('/assets/fonts/ProximaNova-RegularIt.woff2') format('woff2'),
		url('/assets/fonts/ProximaNova-RegularIt.woff') format('woff'),
		url('/assets/fonts/ProximaNova-RegularIt.ttf') format('truetype'),
		url('/assets/fonts/ProximaNova-RegularIt.svg#ProximaNova-RegularIt') format('svg');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova';
	src:
		url('/assets/fonts/ProximaNova-Regular.woff2') format('woff2'),
		url('/assets/fonts/ProximaNova-Regular.woff') format('woff'),
		url('/assets/fonts/ProximaNova-Regular.ttf') format('truetype'),
		url('/assets/fonts/ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src:
		url('/assets/fonts/ProximaNova-SemiboldIt.woff2') format('woff2'),
		url('/assets/fonts/ProximaNova-SemiboldIt.woff') format('woff'),
		url('/assets/fonts/ProximaNova-SemiboldIt.ttf') format('truetype'),
		url('/assets/fonts/ProximaNova-SemiboldIt.svg#ProximaNova-SemiboldIt') format('svg');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova';
	src:
		url('/assets/fonts/ProximaNova-ThinIt.woff2') format('woff2'),
		url('/assets/fonts/ProximaNova-ThinIt.woff') format('woff'),
		url('/assets/fonts/ProximaNova-ThinIt.ttf') format('truetype'),
		url('/assets/fonts/ProximaNova-ThinIt.svg#ProximaNova-ThinIt') format('svg');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova';
	src:
		url('/assets/fonts/ProximaNova-Semibold.woff2') format('woff2'),
		url('/assets/fonts/ProximaNova-Semibold.woff') format('woff'),
		url('/assets/fonts/ProximaNova-Semibold.ttf') format('truetype'),
		url('/assets/fonts/ProximaNova-Semibold.svg#ProximaNova-Semibold') format('svg');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src:
		url('/assets/fonts/ProximaNovaT-Thin.woff2') format('woff2'),
		url('/assets/fonts/ProximaNovaT-Thin.woff') format('woff'),
		url('/assets/fonts/ProximaNovaT-Thin.ttf') format('truetype'),
		url('/assets/fonts/ProximaNovaT-Thin.svg#ProximaNovaT-Thin') format('svg');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src:
		url('/assets/fonts/ProximaNova-BoldIt.woff2') format('woff2'),
		url('/assets/fonts/ProximaNova-BoldIt.woff') format('woff'),
		url('/assets/fonts/ProximaNova-BoldIt.ttf') format('truetype'),
		url('/assets/fonts/ProximaNova-BoldIt.svg#ProximaNova-BoldIt') format('svg');
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: 'Proxima Nova Condensed';
	src: url('/assets/fonts/ProximaNovaCondBold.ttf') format('truetype');
	font-weight: 700;
}

$base_font: 16px;

// @Input: value in pixels (eg: 16px or 16)
// @Action: Converts pixels to 'rem' based on $base_font
// @Output: value in 'em'
@function rem($pixels, $context: $base_font) {

	// To allow user to pass pixels with or without units
	@if (unitless($pixels)) {
		$pixels: $pixels * 1px;
	}

	@if (unitless($context)) {
		$context: $context * 1px;
	}

	@return #{math.div($pixels, $context)}rem;
}

html {
	font-size: $base_font;
}

@media screen and (min-width: 576px) {
	html {
		font-size: 12px;
	}
}

@media screen and (min-width: 768px) {
	html {
		font-size: 14px;
	}
}

@media screen and (min-width: 992px) {
	html {
		font-size: 16px;
	}
}

body {
	font-size: rem(16) !important;
	font-weight: 400 !important;
	font-family: 'Proxima Nova', sans-serif !important;
	--mdc-typography-body2-font-size: rem(16) !important;
	--mdc-typography-body2-font-height: rem(16.5) !important;
	--mdc-typography-body2-font-weight: 400 !important;
	--mdc-typography-body2-font-family: Proxima Nova, sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 600;
}

h2 {
	font-size: rem(21);
}

h4,
.text-21 {
	font-size: rem(21);
}

h6 {
	font-size: rem(16);
}

.small-text {
	font-size: rem(12);
}

.very-small-text {
	font-size: rem(10);
}

.text-18 {
	font-size: rem(18);
}

.text-20 {
	font-size: rem(20);
}

.text-16 {
	font-size: rem(16);
}

.text-10 {
	font-size: rem(10);
}

.text-12 {
	font-size: rem(12);
}

.text-14 {
	font-size: rem(14);
}

.text-24 {
	font-size: 24px;
}

.text-28 {
	font-size: 28px;
}