/// Slightly lighten a color
/// @access public
/// @param {Color} $color - color to tint
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function tint($color, $percentage) {
	@return mix(white, $color, $percentage);
}

/// Slightly darken a color
/// @access public
/// @param {Color} $color - color to shade
/// @param {Number} $percentage - percentage of `$color` in returned color
/// @return {Color}
@function shade($color, $percentage) {
	@return mix(black, $color, $percentage);
}

// clearfix
@mixin clearfix() {
	&::after {
		display: block;
		content: '';
		clear: both;
	}
}

@mixin centerX() {
  left: 50%;
  transform: translateX(-50%);
}

@mixin centerY() {
  top: 50%;
  transform: translateY(-50%);
}

@mixin frame($z-index: auto) {
  @include size();
  position: absolute;
  z-index: $z-index;
}

@mixin size($width: 100%, $height: $width) {
  width: $width;
  height: $height;
}

// global box shadow
.global-box-shadow {
	box-shadow: 0px 0px 7.68px 0.32px rgba(0, 0, 0, 0.1);
	border-width: 1px;
	border-color: $new-border-color;
	border-style: solid;
}
